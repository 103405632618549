'use client';

import { createContext, type ReactNode, useContext } from 'react';
import { usePathname } from 'solito/navigation';
import { EMBEDDED_PATH } from './types';

const context = createContext<boolean>(false);

const { Provider } = context;

export function EmbeddedModeProvider({ children }: { children: ReactNode }) {
  const path = usePathname();
  const isEmbeddedEnabled = !!path?.startsWith(EMBEDDED_PATH);
  return <Provider value={isEmbeddedEnabled}>{children}</Provider>;
}

export const useIsEmbeddedModeEnabled = (): boolean => {
  const isEnabled = useContext(context);
  return isEnabled;
};
