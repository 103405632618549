import { useTsController } from '@ts-react/form';
import { Fieldset } from 'tamagui';
import { z } from 'zod';
import type { FileUploadProps } from '../../FileUpload/types';
import { FileUpload } from '../../FileUpload';

// TODO Change to UploadedFile object shape instead, and remove files from FileUploadFieldProps. field.value can be passed as files to FileUpload instead.
export const FileUploadFieldSchema = z.string().array();

export type FileUploadField = z.infer<typeof FileUploadFieldSchema>;

export type FileUploadFieldProps = Pick<
  FileUploadProps,
  | 'testId'
  | 'category'
  | 'fileTypes'
  | 'onAddDoc'
  | 'onDeleteDoc'
  | 'onInvalidFileUploaded'
  | 'files'
  | 'disabled'
  | 'multiple'
  | 'showFileUploadOnly'
>;

export const FileUploadField = ({
  testId,
  category,
  fileTypes,
  onAddDoc,
  onDeleteDoc,
  onInvalidFileUploaded,
  files,
  disabled,
  multiple,
  showFileUploadOnly,
}: FileUploadFieldProps) => {
  const { error } = useTsController<FileUploadField>();

  return (
    <Fieldset>
      <FileUpload
        testId={testId}
        files={files}
        category={category}
        fileTypes={fileTypes}
        errorMessage={error?.errorMessage}
        onAddDoc={onAddDoc}
        onDeleteDoc={onDeleteDoc}
        onInvalidFileUploaded={onInvalidFileUploaded}
        disabled={disabled}
        multiple={multiple}
        showFileUploadOnly={showFileUploadOnly}
      />
    </Fieldset>
  );
};
