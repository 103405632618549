import type { Emitter } from 'mitt';
import { webViewConsoleMessageExecutor } from 'app/features/WebView/executeActionHooks/webViewConsoleMessageExecutor';
import { webViewToastMessageExecutor } from 'app/features/WebView/executeActionHooks/webViewToastMessageExecutor';
import type { WebViewMessagingPayloads } from 'app/features/WebView/types';

// Add executors to bus
export const webWebProcessorMountListeners = (bus: Emitter<WebViewMessagingPayloads>) => {
  bus.on('webViewConsoleMessage', (payload) => {
    webViewConsoleMessageExecutor({ payload });
  });
  bus.on('webViewToastMessage', (payload) => {
    webViewToastMessageExecutor({ payload });
  });
};

// Remove executors to bus
// This should also remove any custom executors added at the expo page level
export const webWebProcessorUnMountListeners = (bus: Emitter<WebViewMessagingPayloads>) => {
  bus.off('webViewConsoleMessage');
  bus.off('webViewToastMessage');
};
