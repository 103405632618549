'use client';

import type { ReactNode } from 'react';
import { AlertCircle, AlertTriangle, Check, Info } from '@cxnpl/ui/icons';
import { styled, View } from '@cxnpl/ui';

export const toastIconMap: Record<string, ReactNode> = {
  danger: <AlertTriangle />,
  info: <Info />,
  success: <Check />,
  warning: <AlertCircle />,
  attention: <AlertCircle />,
} as const;

export const ToastView = styled(View, {
  gap: '$space.sm',
  alignItems: 'center',
  borderRadius: '$toast/radius/toast',
  paddingHorizontal: '$xl',
  paddingVertical: '$xs',
  flexDirection: 'row',
  flex: 1,
  variants: {
    severity: {
      danger: {
        color: '$alert/color/danger/alert-fg-danger',
        backgroundColor: '$alert/color/danger/alert-bg-danger',
      },
      info: {
        color: '$alert/color/info/alert-fg-info',
        backgroundColor: '$alert/color/info/alert-bg-info',
      },

      success: {
        color: '$alert/color/success/alert-fg-success',
        backgroundColor: '$alert/color/success/alert-bg-success',
      },
      warning: {
        color: '$alert/color/warning/alert-fg-warning',
        backgroundColor: '$alert/color/warning/alert-bg-warning',
      },
      attention: {
        color: '$alert/color/attention/alert-fg-attention',
        backgroundColor: '$alert/color/attention/alert-bg-attention',
      },
    },
  } as const,
  defaultVariants: {
    severity: 'info',
  },
});
