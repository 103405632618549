'use client';

import type { MutexInterface } from 'async-mutex';
import { useCallback, useMemo, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useStore } from 'react-redux';
import useFlag from 'app/hooks/useFlag';
import { axiosInstanceMutex, refreshTokenMutex } from 'app/services/mutex';
import type { RootState } from 'app/store';
import { refreshTokenWeb } from './refreshTokenWebInternal';
import type { UseRefreshToken } from './type';

/**
 * Wrapper around internal refreshToken function to be used in components.
 * Abstracts away the featureFlag call so components don't need to explicitly
 * retrieve the flag themselves.
 *
 * Note: Not to be confused with the mutation useRefreshToken defined in app/users.
 * @returns
 */
export const useRefreshToken: UseRefreshToken = () => {
  const queryClient = useQueryClient();
  const auth0Enabled = useFlag('enableV2Auth0');
  const store = useStore<RootState>();
  const releaseRef = useRef<MutexInterface.Releaser>(() => {
    // Do nothing
  });

  const refresh = useCallback<ReturnType<UseRefreshToken>[0]>(
    async (args = {}) => {
      const { entityId, onRefresh } = args;
      // Need to wait for other refresh token requests to finish, as they would invalidate the current refresh token, making this request fail.
      await refreshTokenMutex.waitForUnlock();
      const release = await refreshTokenMutex.acquire();
      releaseRef.current = release;
      // Need to wait for any refresh token requests made from response interceptor to finish as well.
      await axiosInstanceMutex.waitForUnlock();
      try {
        // Auth token info may have changed after waiting for mutex. We need to fetch the latest.
        const { tokenInfo } = store.getState().auth;
        const { entityAccountId: currentEntityAccountId } = tokenInfo;

        const nextEntityAccountId = entityId || currentEntityAccountId || undefined;

        // Interceptors will update authTokens and tokenInfo and handle errors.
        await refreshTokenWeb({
          entityId: nextEntityAccountId,
          auth0Enabled,
        });
        await onRefresh?.();
        if (nextEntityAccountId && nextEntityAccountId !== currentEntityAccountId) {
          // Reset all cached data back to initial state. Does not clear subscribers like `queryClient.clear()` does.
          await queryClient.resetQueries();
        }
      } finally {
        release();
      }
    },
    [auth0Enabled, queryClient, store]
  );

  const releaseLock = useCallback<ReturnType<UseRefreshToken>[1]>(() => {
    releaseRef.current();
  }, []);

  return useMemo(() => [refresh, releaseLock], [refresh, releaseLock]);
};
