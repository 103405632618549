'use client';

import type { PropsWithChildren } from 'react';
import type { AnimatePresenceProps } from 'tamagui';
import { AnimatePresence as TamaguiAnimatePresence } from 'tamagui';
import { useReducedMotionEnabled } from '../../hooks';

/**
 * Wrapper on Tamagui's regular animate presence. This component removes the animation if the user requested the reduced
 * motion and doesn't want to see the animations.
 *
 * On top of that, the regular component temporarily has two children in the screen at once which causes some of our
 * tests to fail as it finds duplicate elements. We run the Playwright tests with the enforced reduced motion to avoid
 * this issue.
 */
export const AnimatePresence = ({ children, ...rest }: PropsWithChildren<AnimatePresenceProps>) => {
  const reduceMotion = useReducedMotionEnabled();
  if (reduceMotion) {
    return <>{children}</>;
  }

  return <TamaguiAnimatePresence {...rest}>{children}</TamaguiAnimatePresence>;
};
