import type { AuthenticationResultType, Refresh200 } from '@cxnpl/api/types';
import { refresh as auth0Refresh } from '@cxnpl/api/session-management-apis';
import { refreshToken as cognitoRefresh } from '@cxnpl/api/users';
import { appClient } from 'app/utils/appClient';

/**
 * Refresh user token via configured axios instance.
 * Axios instance has interceptors which will refresh the token if it is expired.
 * If you wish to call this method within a component, consider using the hook
 * `useRefreshToken` instead, defined in `app/api/internal/useRefreshToken.ts`.
 *
 * @param entityId - The entity ID of the business to refresh the token for.
 * @param auth0Enabled - Whether Auth0 is enabled.
 */
export async function refreshTokenWeb(
  { entityId, auth0Enabled }: { entityId?: string; auth0Enabled: boolean } = { auth0Enabled: false }
): Promise<AuthenticationResultType | Refresh200> {
  if (auth0Enabled) {
    const result = await auth0Refresh({ entityId, appClient });
    return result;
  }
  const result = cognitoRefresh({ entityId, appClient }) as AuthenticationResultType; // Refresh token will be obtained from cookies
  return result;
}
