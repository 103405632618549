import type { ReactElement } from 'react';
import { Dimensions } from 'react-native';
import type { YStackProps } from 'tamagui';
import { isWeb, YStack } from 'tamagui';
import { Portal } from '../Portal';
import { useSafeAreaInsets } from '../../hooks/safeAreaInsets';
import { AnimatePresence } from '../AnimatePresence';

interface FullScreenOverlayBaseProps {
  visible: boolean;
  children: ReactElement | null;
  overlayProps?: YStackProps;
}

const windowHeight = Dimensions.get('window').height;

export const FullScreenOverlayBase = ({ visible, children, overlayProps }: FullScreenOverlayBaseProps) => {
  const startValue = isWeb ? '100%' : windowHeight;
  const insets = useSafeAreaInsets();

  return (
    <AnimatePresence>
      {visible ? (
        <Portal>
          <YStack
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height={startValue}
            marginTop={-insets.top}
            paddingTop={insets.top}
            justifyContent="flex-start"
            alignItems="center"
            animation="250ms"
            enterStyle={{ y: startValue }}
            exitStyle={{ y: startValue }}
            {...overlayProps}
          >
            {children}
          </YStack>
        </Portal>
      ) : null}
    </AnimatePresence>
  );
};
