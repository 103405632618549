'use client';

import type { PopoverContentProps } from 'tamagui';
import { Adapt, isWeb, Popover, Stack, Theme, withStaticProperties, XStack, YStack, Separator } from 'tamagui';
import React, { forwardRef, type ReactElement, type ReactNode, useImperativeHandle, useState } from 'react';
import { X } from '@tamagui/lucide-icons';
import type { ElevationProps } from '../Elevation';
import { Elevation } from '../Elevation';
import { useSafeAreaInsets } from '../../hooks';
import { Text } from '../Text';

export interface MenuRef {
  closeMenu: () => void;
}

export interface MenuProps {
  ref?: MenuRef;
  header?: React.ReactElement;
  config?: {
    showHeaderSeparator: boolean;
    showItemSeparator: boolean;
  };
  items: {
    onPress: () => void;
    id: string;
    isSelected?: boolean;
    isVisible?: boolean;
    icon?: ReactElement;
    label: ReactElement | string;
    badge?: ReactElement;
  }[];
  children: React.ReactElement;
  elevation?: ElevationProps['level'];
  popoverProps?: PopoverContentProps;
  testID?: string;
  hasClose?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
}

// Not sure why this is needed but native doesn't trigger if the props aren't passed to the children
const TriggerWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Popover.Trigger asChild>
      <Stack asChild={!isWeb}>{children}</Stack>
    </Popover.Trigger>
  );
};

const MenuBase = forwardRef(
  (
    {
      header,
      config = { showHeaderSeparator: false, showItemSeparator: true },
      items,
      children,
      elevation = 1,
      hasClose = true,
      popoverProps,
      testID,
      onOpenChange,
    }: MenuProps,
    ref
  ) => {
    const [open, setOpen] = useState(false);

    // Expose the setOpen function to the parent via the ref
    useImperativeHandle(ref, () => ({
      closeMenu: () => {
        setOpen(false);
      },
    }));

    const handleOpenChange = (isOpen: boolean) => {
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    };

    const insets = useSafeAreaInsets();
    return (
      <Popover size="$xs" allowFlip placement="bottom" open={open} onOpenChange={handleOpenChange}>
        <TriggerWrapper>{children}</TriggerWrapper>

        <Popover.Adapt when="ltTablet">
          <Popover.Sheet
            modal
            dismissOnSnapToBottom
            snapPointsMode="fit"
            dismissOnOverlayPress
            portalProps={{
              marginTop: insets.top, // Hack to set a max height below insets in native
            }}
          >
            <Popover.Sheet.Overlay
              animation="100ms"
              enterStyle={{ opacity: 0 }}
              exitStyle={{ opacity: 0 }}
              opacity={0.5}
              backgroundColor="black"
              marginTop={-insets.top} // Hack to set a max height below insets in native
            />
            <Popover.Sheet.Frame backgroundColor="$background/surface">
              <Popover.Sheet.ScrollView>
                <Adapt.Contents />
              </Popover.Sheet.ScrollView>
            </Popover.Sheet.Frame>
          </Popover.Sheet>
        </Popover.Adapt>

        <Popover.Content
          disableFocusScope
          marginTop="$sm"
          padding={0}
          borderWidth={0}
          borderRadius="$surface/radius/surface-radius"
          enterStyle={{ y: -10, opacity: 0 }}
          exitStyle={{ y: -10, opacity: 0 }}
          elevate
          animation={[
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          {...popoverProps}
        >
          <Elevation
            testID={testID}
            level={elevation}
            backgroundColor="$background/surface"
            overflow="hidden"
            $ltTablet={{
              maxWidth: '100%',
              borderRadius: 0,
            }}
            maxWidth={350}
            minWidth={300}
            borderRadius="$surface/radius/surface-radius"
          >
            {header ? (
              <>
                <XStack padding="$lg">
                  <XStack
                    $ltTablet={{ paddingHorizontal: '$sm', paddingTop: '$sm' }}
                    justifyContent="space-between"
                    alignItems="center"
                    flex={1}
                  >
                    {header}
                    {hasClose ? (
                      <Stack
                        onPress={() => {
                          setOpen(false);
                        }}
                      >
                        <X />
                      </Stack>
                    ) : null}
                  </XStack>
                </XStack>
                {config.showHeaderSeparator ? (
                  <Separator borderBottomColor="$border/app-subdued" borderBottomWidth={1} />
                ) : null}
              </>
            ) : null}
            <YStack $ltTablet={{ paddingHorizontal: '$lg', paddingBottom: !isWeb ? insets.bottom : '$lg' }}>
              {items.map((item, index) => {
                if (item.isVisible === false) {
                  return null;
                }
                return (
                  <React.Fragment key={item.id}>
                    <Theme inverse={item.isSelected}>
                      <XStack
                        alignItems="center"
                        testID={item.id}
                        onPress={() => {
                          setOpen(false);
                          item.onPress();
                        }}
                        flexGrow={1}
                        cursor="pointer"
                        hoverStyle={{
                          backgroundColor: '$background/primary-hover',
                        }}
                        backgroundColor="$background/surface"
                        paddingVertical="$lg"
                        borderRadius="$md"
                        paddingHorizontal="$lg"
                        $ltTablet={{ paddingHorizontal: 0 }}
                      >
                        <XStack
                          gap="$space.sm"
                          justifyContent="space-between"
                          flex={1}
                          $ltTablet={{ paddingHorizontal: '$sm' }}
                        >
                          <XStack alignItems="center" gap="$space.sm" flex={1}>
                            <Stack flex={0}>{item.icon ? item.icon : null}</Stack>
                            {typeof item.label === 'string' ? <Text>{item.label}</Text> : item.label}
                          </XStack>
                          {item.badge ? item.badge : null}
                        </XStack>
                      </XStack>
                    </Theme>
                    {config.showItemSeparator && items.length - 1 !== index ? (
                      <Separator borderBottomColor="$border/app-subdued" borderBottomWidth={1} />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </YStack>
          </Elevation>
        </Popover.Content>
      </Popover>
    );
  }
);

MenuBase.displayName = 'menu-base';

export const Menu = withStaticProperties(MenuBase, {
  Close: Popover.Close,
});
