import { useToastController } from '@tamagui/toast';
import branding from '@cxnpl/ui/brand';
import { postMessageToNative } from 'app/features/WebView/messageNative';
import { useIsEmbeddedModeEnabled } from 'app/services/embeddedMode';

export const useToast = () => {
  const toast = useToastController();
  const isEmbedded = useIsEmbeddedModeEnabled();

  const hideToast = () => {
    toast.hide();
  };

  const showToast = ({
    title,
    severity,
  }: {
    title: string;
    severity: 'danger' | 'info' | 'success' | 'warning' | 'attention';
  }) => {
    if (isEmbedded && branding.brandName !== 'MYOB') {
      postMessageToNative({
        type: 'webViewToastMessage',
        payload: { severity, title },
      });
    } else {
      toast.show(title, {
        duration: 4000,
        customData: { severity },
      });
    }
  };

  return { showToast, hideToast };
};

export default useToast;
