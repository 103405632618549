const SHOW_LOGGING = false;
/**
 * Process logging event
 */
export const webViewConsoleMessageExecutor = ({ payload }: { payload: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition  -- debugging
  if (SHOW_LOGGING) {
    // eslint-disable-next-line no-console -- debugging
    console.info(`\x1b[33m[WEB VIEW LOG]\x1b[0m: ${JSON.stringify(payload)}`);
  }
};
