import { appClient } from 'app/utils/appClient';
import type { WebViewMessageType, WebViewMessagingPayloads } from './types';

declare global {
  interface Window {
    sendMessageToNative?: ({
      type,
      payload,
    }: {
      type: WebViewMessageType;
      payload: WebViewMessagingPayloads[WebViewMessageType];
    }) => void;
  }
}

// Check if the window has been loaded with ReactNativeWebView
// If so send message to native
export const postMessageToNative = ({
  type,
  payload,
}: {
  type: WebViewMessageType;
  payload: WebViewMessagingPayloads[WebViewMessageType];
}) => {
  // Only access window if exists and running in web
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Sanity check
  if (appClient === 'WEB' && window && window.sendMessageToNative) {
    window.sendMessageToNative({ type, payload });
  }
};
