import { useAppSelector } from '../../store';

const useFlag = (flagName: string): boolean => {
  const flags = useAppSelector((state) => state.featureFlag);

  if (flagName === 'enableV2KYCRetailOnboarding') {
    return true;
  }

  return !!flags[flagName];
};

export default useFlag;
