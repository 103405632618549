//General utils to handle promises in dictionaries
interface AnyDictionary {
  [key: string]: unknown;
}

export async function resolveDictPromises(dict: AnyDictionary) {
  const entries = Object.entries(dict);
  const resolvedEntries = await Promise.all(
    entries.map(async ([key, promise]) => {
      const resolvedValue = await promise;
      return [key, resolvedValue];
    })
  );
  const resolvedDict: AnyDictionary = Object.fromEntries(resolvedEntries);
  return resolvedDict;
}
export async function resolveArrayOfDictPromises(arrayOfDicts: AnyDictionary[]) {
  const resolvedDicts = await Promise.all(arrayOfDicts.map(resolveDictPromises));
  return resolvedDicts;
}

export const base64ToUint8Array = (base64: string): Uint8Array => {
  const binaryString = atob(base64); // Decode Base64 to binary string
  const length = binaryString.length;
  const uint8Array = new Uint8Array(length);

  for (let i = 0; i < length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  return uint8Array;
};
