'use client';

/* eslint-disable @typescript-eslint/no-unsafe-argument -- from Tamagui implementation */
/* eslint-disable @typescript-eslint/no-explicit-any -- from Tamagui implementation */
import type { SideObject } from '@floating-ui/react';
import {
  flip,
  inner,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInnerOffset,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import { isClient, isWeb, useIsomorphicLayoutEffect } from '@tamagui/constants';
import { useEvent, useIsTouchDevice } from '@tamagui/core';
import * as React from 'react';
import { flushSync } from 'react-dom';
import { SCROLL_ARROW_THRESHOLD, WINDOW_PADDING } from './constants';
import { SelectItemParentProvider, SelectProvider, useSelectContext, useSelectItemParentContext } from './context';
import type { SelectImplProps } from './types';

// TODO use id for focusing from label
export const SelectInlineImpl = (props: SelectImplProps) => {
  const {
    __scopeSelect,
    children,
    open = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- From Tamagui implementation
    selectedIndexRef,
    listContentRef,
  } = props;

  const selectContext = useSelectContext('SelectSheetImpl', __scopeSelect);
  const selectItemParentContext = useSelectItemParentContext('SelectSheetImpl', __scopeSelect);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- From Tamagui implementation
  const { setActiveIndex, selectedIndex, activeIndex, forceUpdate } = selectContext;

  const { setOpen, setSelectedIndex } = selectItemParentContext;

  const [scrollTop, setScrollTop] = React.useState(0);
  const touch = useIsTouchDevice();

  // eslint-disable-next-line @typescript-eslint/array-type -- From Tamagui implementation
  const listItemsRef = React.useRef<Array<HTMLElement | null>>([]);
  const overflowRef = React.useRef<null | SideObject>(null);
  const upArrowRef = React.useRef<HTMLDivElement | null>(null);
  const downArrowRef = React.useRef<HTMLDivElement | null>(null);
  const allowSelectRef = React.useRef(false);
  const allowMouseUpRef = React.useRef(true);
  const selectTimeoutRef = React.useRef<any>();
  const state = React.useRef({
    isMouseOutside: false,
  });

  const [controlledScrolling, setControlledScrolling] = React.useState(false);
  const [fallback, setFallback] = React.useState(false);
  const [innerOffset, setInnerOffset] = React.useState(0);
  const [blockSelection, setBlockSelection] = React.useState(false);
  const floatingStyle = React.useRef({});

  // Wait for scroll position to settle before showing arrows to prevent
  // interference with pointer events.
  useIsomorphicLayoutEffect(() => {
    queueMicrotask(() => {
      if (!open) {
        setScrollTop(0);
        setFallback(false);
        setActiveIndex(null);
        setControlledScrolling(false);
      }
    });
  }, [open, setActiveIndex]);

  // close when mouseup outside select
  if (isWeb && isClient) {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- from Tamagui implementation
    useIsomorphicLayoutEffect(() => {
      if (!open) {
        return;
      }
      const mouseUp = (_e: MouseEvent) => {
        if (state.current.isMouseOutside) {
          setOpen(false);
        }
      };
      document.addEventListener('mouseup', mouseUp);
      return () => {
        document.removeEventListener('mouseup', mouseUp);
      };
    }, [open]);
  }

  const flipOrShiftMiddlewares = [
    touch ? shift({ crossAxis: true, padding: WINDOW_PADDING }) : flip({ padding: WINDOW_PADDING }),
  ];

  const { x, y, strategy, context, refs, update } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: 'bottom-start',
    middleware: [
      size({
        apply({
          rects: {
            reference: { width },
          },
        }) {
          floatingStyle.current = {
            minWidth: width, //Removed a + 8 that made the open menu wider than the trigger
          };
        },
      }),
      ...flipOrShiftMiddlewares,
      inner({
        listRef: listItemsRef,
        overflowRef,
        index: selectedIndex,
        offset: innerOffset,
        // onFallbackChange: setFallback,
        padding: 10,
        minItemsVisible: touch ? 10 : 4,
        referenceOverflowThreshold: 20,
      }),
      offset({ crossAxis: 0 }), //crossAxis 0 so it does not move to the left of the trigger
    ],
  });

  useIsomorphicLayoutEffect(() => {
    window.addEventListener('resize', update);
    if (open) {
      update();
    }
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression -- from Tamagui implementation
    return () => window.removeEventListener('resize', update);
  }, [update, open]);

  const floatingRef = refs.floating;

  const showUpArrow = open && scrollTop > SCROLL_ARROW_THRESHOLD;
  const showDownArrow =
    open &&
    floatingRef.current &&
    scrollTop < floatingRef.current.scrollHeight - floatingRef.current.clientHeight - SCROLL_ARROW_THRESHOLD;

  const onMatch = useEvent((index: number) => {
    const fn = open ? setActiveIndex : setSelectedIndex;
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression -- from Tamagui implementation
    return fn(index);
  });

  const interactionsProps = [
    useClick(context, { event: 'mousedown' }),
    useDismiss(context, { outsidePress: false }),
    useRole(context, { role: 'listbox' }),
    useInnerOffset(context, {
      enabled: !fallback && (!!showUpArrow || !!showDownArrow),
      onChange: setInnerOffset,
      overflowRef,
      scrollRef: refs.floating,
    }),
    useListNavigation(context, {
      listRef: listItemsRef,
      activeIndex: activeIndex || 0,
      selectedIndex,
      onNavigate: setActiveIndex,
    }),
    useTypeahead(context, {
      listRef: listContentRef,
      onMatch,
      selectedIndex,
      activeIndex,
    }),
  ];

  const interactions = useInteractions(
    // unfortunately these memos will just always break due to floating-ui context always changing :/
    React.useMemo(() => {
      return interactionsProps;
      // eslint-disable-next-line react-hooks/exhaustive-deps -- from Tamagui implementation
    }, interactionsProps)
  );

  const interactionsContext = React.useMemo(() => {
    return {
      ...interactions,
      getReferenceProps() {
        return interactions.getReferenceProps({
          ref: refs.reference as any,
          className: 'SelectTrigger',
          onKeyDown(event) {
            if (event.key === 'Enter' || (event.key === ' ' && !context.dataRef.current.typing)) {
              event.preventDefault();
              setOpen(true);
            }
          },
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-shadow -- from Tamagui implementation
      getFloatingProps(props: React.HTMLProps<HTMLElement> | undefined) {
        return interactions.getFloatingProps({
          ref: refs.floating,
          className: 'Select',
          ...props,
          style: {
            position: strategy,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- from Tamagui implementation
            top: y ?? '',
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- from Tamagui implementation
            left: x ?? '',
            outline: 0,
            scrollbarWidth: 'none',
            ...floatingStyle.current,
            ...props?.style,
          },
          onPointerEnter() {
            setControlledScrolling(false);
            state.current.isMouseOutside = false;
          },
          onPointerLeave() {
            state.current.isMouseOutside = true;
          },
          onPointerMove() {
            state.current.isMouseOutside = false;
            setControlledScrolling(false);
          },
          onKeyDown() {
            setControlledScrolling(true);
          },
          onContextMenu(e) {
            e.preventDefault();
          },
          onScroll(event) {
            flushSync(() => {
              setScrollTop(event.currentTarget.scrollTop);
            });
          },
        });
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- from Tamagui implementation
  }, [refs.reference.current, x, y, refs.floating.current, interactions]);

  // effects

  // @ts-expect-error until -- From Tamagui implementation
  useIsomorphicLayoutEffect(() => {
    if (open) {
      selectTimeoutRef.current = setTimeout(() => {
        allowSelectRef.current = true;
      }, 300);

      return () => {
        clearTimeout(selectTimeoutRef.current);
      };
    }
    allowSelectRef.current = false;
    allowMouseUpRef.current = true;
    setInnerOffset(0);
    setFallback(false);
    setBlockSelection(false);
  }, [open]);

  useIsomorphicLayoutEffect(() => {
    if (!open && state.current.isMouseOutside) {
      state.current.isMouseOutside = false;
    }
  }, [open]);

  // Replacement for `useDismiss` as the arrows are outside of the floating
  // element DOM tree.
  // @ts-expect-error until -- From Tamagui implementation
  useIsomorphicLayoutEffect(() => {
    function onPointerDown(e: PointerEvent) {
      const target = e.target as Node;
      if (
        !(
          refs.floating.current?.contains(target) ||
          upArrowRef.current?.contains(target) ||
          downArrowRef.current?.contains(target)
        )
      ) {
        setOpen(false);
        setControlledScrolling(false);
      }
    }

    if (open) {
      document.addEventListener('pointerdown', onPointerDown);
      return () => {
        document.removeEventListener('pointerdown', onPointerDown);
      };
    }
  }, [open, refs, setOpen]);

  // Scroll the `activeIndex` item into view only in "controlledScrolling"
  // (keyboard nav) mode.
  React.useEffect(() => {
    if (open && controlledScrolling) {
      // eslint-disable-next-line eqeqeq -- From Tamagui implementation
      if (activeIndex != null) {
        listItemsRef.current[activeIndex]?.scrollIntoView({ block: 'nearest' });
      }
    }

    setScrollTop(refs.floating.current?.scrollTop ?? 0);
  }, [open, refs, controlledScrolling, activeIndex]);

  // Scroll the `selectedIndex` into view upon opening the floating element.
  React.useEffect(() => {
    if (open && fallback) {
      // eslint-disable-next-line eqeqeq, @typescript-eslint/no-unnecessary-condition -- From Tamagui implementation
      if (selectedIndex != null) {
        listItemsRef.current[selectedIndex]?.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [open, fallback, selectedIndex]);

  // Unset the height limiting for fallback mode. This gets executed prior to
  // the positioning call.
  useIsomorphicLayoutEffect(() => {
    if (refs.floating.current && fallback) {
      refs.floating.current.style.maxHeight = '';
    }
  }, [refs, fallback]);

  // We set this to true by default so that events bubble to forms without JS (SSR)
  // const isFormControl = trigger ? Boolean(trigger.closest('form')) : true
  // const [bubbleSelect, setBubbleSelect] = React.useState<HTMLSelectElement | null>(null)
  // const triggerPointerDownPosRef = React.useRef<{ x: number; y: number } | null>(null)

  return (
    <SelectProvider
      scope={__scopeSelect}
      {...(selectContext as Required<typeof selectContext>)}
      setScrollTop={setScrollTop}
      setInnerOffset={setInnerOffset}
      fallback={fallback}
      floatingContext={context}
      activeIndex={activeIndex}
      canScrollDown={!!showDownArrow}
      canScrollUp={!!showUpArrow}
      controlledScrolling={controlledScrolling}
      blockSelection={blockSelection}
      upArrowRef={upArrowRef}
      downArrowRef={downArrowRef}
      update={update}
    >
      <SelectItemParentProvider
        scope={__scopeSelect}
        {...selectItemParentContext}
        allowMouseUpRef={allowMouseUpRef}
        allowSelectRef={allowSelectRef}
        dataRef={context.dataRef}
        interactions={interactionsContext}
        listRef={listItemsRef}
        selectTimeoutRef={selectTimeoutRef}
      >
        {children}
      </SelectItemParentProvider>
    </SelectProvider>
  );
};
