import { Button, Stack, Popover as TamaguiPopover, YStack } from 'tamagui';
import { Info } from '@tamagui/lucide-icons';
import { H5 } from '../Heading';
import { Text } from '../Text';
import type { PopoverProps } from './types';

//Tooltip info button
const TooltipInfoButton = <Button color="$foreground/surface-subdued" circular unstyled icon={Info} />;

export const Popover = ({
  testID,
  children,
  title,
  targetElement,
  placement = 'bottom',
  allowFlip = true,
  hoverable = true,
}: PopoverProps) => {
  return (
    <TamaguiPopover placement={placement} allowFlip={allowFlip} hoverable={hoverable}>
      <TamaguiPopover.Trigger testID={testID || 'popover'} asChild>
        <Stack style={{ display: 'inline' }}>{targetElement === 'info' ? TooltipInfoButton : targetElement}</Stack>
      </TamaguiPopover.Trigger>
      <TamaguiPopover.Content
        enterStyle={{ y: 0, opacity: 0 }}
        exitStyle={{ y: 0, opacity: 0 }}
        padding="$space.xl"
        maxWidth={270}
        backgroundColor="$background/surface-inverse"
        borderRadius="$surface/radius/surface-radius"
        marginHorizontal="$space.xl"
        elevate
        animation={[
          'quick',
          {
            opacity: {
              overshootClamping: true,
            },
          },
        ]}
      >
        <TamaguiPopover.Arrow backgroundColor="$background/surface-inverse" />
        <YStack display="flex" gap="$lg" alignItems="flex-start">
          {title ? <H5 color="$foreground/surface-inverse">{title}</H5> : null}
          {typeof children === 'string' ? (
            <Text color="$foreground/surface-inverse" variant="bodySmall">
              {children}
            </Text>
          ) : (
            children
          )}
        </YStack>
      </TamaguiPopover.Content>
    </TamaguiPopover>
  );
};
