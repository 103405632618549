'use client';
import { Fieldset, Stack, XStack, YStack } from 'tamagui';
import { useTranslation } from 'react-i18next';
import { useTsController } from '@ts-react/form';
import { z } from 'zod';
import { Input } from '../../Input';
import { Select } from '../../Select';
import { Text } from '../../Text';
import { Popover } from '../../Popover';

type FrequencyOptionTypes = 'WEEKLY' | 'FORTNIGHTLY' | 'MONTHLY' | 'QUARTERLY' | 'YEARLY';
export interface AmountFrequencyFieldProps {
  size?: '$lg' | '$md' | '$sm' | '$xl' | undefined;
  disabled?: boolean;
  testID?: string;
  hideError?: boolean;
  fieldLabel?: string;
  info?: { title: string; message: string };
  frequencyOptions?: FrequencyOptionTypes[];
}

export const createAmountFrequencySchema = (params?: {
  base?: z.RawCreateParams;
  amount?: z.ZodNumber;
  frequency?: z.ZodString;
}) => {
  return z.object(
    {
      amount:
        params?.amount ??
        z.number({
          required_error: 'This field is required',
          invalid_type_error: 'Please enter a valid number',
        }),
      frequency: params?.frequency ?? z.string({ required_error: 'This field is required' }),
    },
    params?.base ?? {
      required_error: 'This field is required',
    }
  );
};

const partial = z.object({ amount: z.number(), frequency: z.string() }).partial();
export type PartialAmountFrequencySchema = typeof partial;

export type AmountFrequencySchema = ReturnType<typeof createAmountFrequencySchema>;

export function AmountFrequencyField({
  size = '$lg',
  disabled,
  testID,
  hideError,
  fieldLabel,
  info,
  frequencyOptions = ['WEEKLY', 'FORTNIGHTLY', 'MONTHLY', 'YEARLY'],
}: AmountFrequencyFieldProps): JSX.Element {
  const { t } = useTranslation();
  const { field, error, formState } = useTsController<z.infer<AmountFrequencySchema>>();
  const amount = field.value?.amount;
  const frequency = field.value?.frequency;
  const { isSubmitting } = formState;
  const isDisabled = disabled || isSubmitting;

  const dispatchValue = (value: string) => {
    // Strip conversion
    const newValue = value.replace(/,/g, '');

    // Do not update value if a value is given that is not a number
    if (value !== '' && isNaN(Number(newValue))) {
      return;
    }

    // Do not update value if its over max safe value
    if (Number(newValue) > Number.MAX_SAFE_INTEGER) {
      return;
    }

    field.onChange({
      ...field.value,
      amount: newValue ? Number(newValue) : undefined,
    });
  };

  const convertValue = (value?: number) => {
    if (value !== 0 && !Number(value)) {
      return '';
    }

    return Intl.NumberFormat('en-EN').format(Number(value));
  };

  const displayValue = amount !== undefined ? convertValue(amount) : '';

  const mapRepaymentFrequencyToLabel: Record<FrequencyOptionTypes, string> = {
    FORTNIGHTLY: t('lending.common.repaymentFrequency.fortnightly'),
    MONTHLY: t('lending.common.repaymentFrequency.monthly'),
    QUARTERLY: t('lending.common.repaymentFrequency.quarterly'),
    WEEKLY: t('lending.common.repaymentFrequency.weekly'),
    YEARLY: t('lending.common.repaymentFrequency.annually'),
  };

  return (
    <Fieldset>
      <YStack gap="$lg">
        <XStack gap="$sm" alignItems="center">
          {fieldLabel ? <Text variant="bodyMediumEm">{fieldLabel}</Text> : null}
          {info ? (
            <Popover targetElement="info" title={info.title}>
              {info.message}
            </Popover>
          ) : null}
        </XStack>
        <XStack gap="$xl" width="100%">
          <Stack testID={`${testID || field.name}-amount-container`} flex={1} flexBasis={0} width="100%">
            <Input
              testID={`${testID || field.name}-amount-input`}
              width="100%"
              size={size}
              disabled={isDisabled}
              inputMode="numeric"
              onBlur={field.onBlur}
              onChangeText={(text) => {
                dispatchValue(text);
              }}
              value={`${displayValue}`}
              error={!hideError ? error?.errorMessage || error?.amount?.errorMessage : ''}
              status={error?.errorMessage || error?.amount?.errorMessage ? 'error' : undefined}
              prefix="$"
            />
          </Stack>
          {frequencyOptions.length > 0 ? (
            <Stack testID={`${testID || field.name}-frequency-container`} flex={1} flexBasis={0} width="100%">
              <Select
                ref={field.ref}
                width="100%"
                size={size}
                testID={`${testID || field.name}-frequency-input`}
                disabled={isDisabled}
                value={frequency ?? ''}
                error={error?.errorMessage || error?.frequency?.errorMessage}
                items={frequencyOptions.map((f) => {
                  return {
                    value: f,
                    label: mapRepaymentFrequencyToLabel[f],
                  };
                })}
                itemToValue={(item) => {
                  return item?.value ?? '';
                }}
                itemToLabel={(item) => {
                  return item?.label ?? '';
                }}
                onValueChange={(value) => {
                  field.onChange({
                    ...field.value,
                    frequency: value,
                  });
                }}
                placeholder="Select frequency"
              />
            </Stack>
          ) : null}
        </XStack>
      </YStack>
    </Fieldset>
  );
}
