// Source: https://github.com/tamagui/tamagui/blob/master/packages/toggle-group/src/Toggle.tsx

import { useControllableState } from 'tamagui';
import { Stack } from '@tamagui/web';
import { forwardRef, useId } from 'react';
import { match } from 'ts-pattern';
import type { ToggleElement, ToggleProps } from './types';
import { ToggleText } from './components/ToggleText';
import { BaseToggleFrame } from './components/BaseToggleFrame';
import { ToggleIcon } from './components/ToggleIcon';

/* -------------------------------------------------------------------------------------------------
 * Toggle
 * -----------------------------------------------------------------------------------------------*/

// Combinations of Primitives toggles
// Circular button - no icon
// Button with icon in row or column format
// Button without icon in row only
// Card with Icon in row or column format
// Card without Icon in row only
export const Toggle = forwardRef<ToggleElement, ToggleProps>(function Toggle(props, forwardedRef) {
  const {
    children,
    pressed: pressedProp,
    defaultPressed = false,
    onPressedChange,
    icon: IconComponent,
    testID,
    maxWidth,
    alignment,
    ...buttonProps
  } = props;

  const [pressed = false, setPressed] = useControllableState({
    prop: pressedProp,
    onChange: onPressedChange,
    defaultProp: defaultPressed,
  });
  const labelId = useId();

  const hasIcon = !!props.icon;
  const circular =
    buttonProps.variant === 'button' && typeof children === 'string' && children.length === 1 && !hasIcon;
  const variant = buttonProps.variant;
  const isColumnMode = alignment === 'column';

  // Basic dynamic props shared across all toggle frames
  const toggleFrameProps = {
    testID: testID ? testID : 'toggle-id',
    active: pressed,
    'aria-labelledby': labelId,
    'aria-pressed': pressed,
    'data-state': pressed ? 'on' : 'off',
    'data-disabled': props.disabled ? '' : undefined,
    ...buttonProps, // Additional props passed in
    ref: forwardedRef,
    onPress: () => {
      if (!props.disabled) {
        setPressed(!pressed); // Update pressed state
      }
    },
    disabled: !!props.disabled,
  };

  // Simple icon
  const formattedIcon = IconComponent ? (
    <ToggleIcon active={pressed} icon={IconComponent} disabled={!!props.disabled} />
  ) : null;

  // Format string and jsx for native and web
  const formattedChildren =
    typeof children === 'string' ? (
      <Stack flexDirection="row" flex={1} alignItems="center" justifyContent={props.icon ? 'flex-start' : 'center'}>
        <ToggleText id={labelId} active={pressed} disabled={!!props.disabled} variant="bodyMedium">
          {children}
        </ToggleText>
      </Stack>
    ) : (
      children
    );

  return (
    match({ variant, circular, hasIcon, isColumnMode })
      // Card - No icon - not circular - row
      // Often used in forms - no flex
      .with({ variant: 'card', hasIcon: false, circular: false }, () => {
        return (
          <BaseToggleFrame
            {...toggleFrameProps}
            maxWidth={maxWidth}
            flexWrap="wrap"
            borderRadius="$toggle/radius/toggle-radius-multi"
          >
            {formattedChildren}
          </BaseToggleFrame>
        );
      })
      // Button - No Icon - row
      // Often used in forms - no flex
      .with({ variant: 'button', hasIcon: false, circular: false }, () => {
        return (
          <BaseToggleFrame
            {...toggleFrameProps}
            maxWidth={maxWidth}
            flexWrap="wrap"
            borderRadius="$toggle/radius/toggle-radius-single"
          >
            {formattedChildren}
          </BaseToggleFrame>
        );
      })
      // Card - Icon - row
      .with({ variant: 'card', hasIcon: true, circular: false, isColumnMode: false }, () => {
        return (
          <BaseToggleFrame
            {...toggleFrameProps}
            width="100%"
            maxWidth={maxWidth}
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            borderRadius="$toggle/radius/toggle-radius-multi"
          >
            {formattedIcon}
            {formattedChildren}
          </BaseToggleFrame>
        );
      })
      // Button - Icon - Row
      .with({ variant: 'button', hasIcon: true, circular: false, isColumnMode: false }, () => {
        return (
          <BaseToggleFrame
            {...toggleFrameProps}
            flexDirection="row"
            width="100%"
            alignItems="center"
            justifyContent="flex-start"
            borderRadius="$toggle/radius/toggle-radius-single"
            maxWidth={maxWidth}
          >
            {formattedIcon}
            {formattedChildren}
          </BaseToggleFrame>
        );
      })
      // Card - Icon - Column
      .with({ variant: 'card', hasIcon: true, circular: false, isColumnMode: true }, () => {
        return (
          <BaseToggleFrame
            {...toggleFrameProps}
            maxWidth={maxWidth}
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexGrow={1}
            flexShrink={1}
            flexBasis="100%"
            borderRadius="$toggle/radius/toggle-radius-multi"
          >
            {formattedIcon}
            {formattedChildren}
          </BaseToggleFrame>
        );
      })
      // Button - Icon - Column
      .with({ variant: 'button', hasIcon: true, circular: false, isColumnMode: true }, () => {
        return (
          <BaseToggleFrame
            {...toggleFrameProps}
            maxWidth={maxWidth}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            flexShrink={1}
            flexBasis="100%"
            borderRadius="$toggle/radius/toggle-radius-single"
          >
            {formattedIcon}
            {formattedChildren}
          </BaseToggleFrame>
        );
      })
      // Button - Circular
      .with({ variant: 'button', circular: true }, () => {
        return (
          <BaseToggleFrame
            {...toggleFrameProps}
            borderRadius="$radius.round"
            height={props.size === 'lg' ? '$button/size/lg' : '$button/size/md'}
            padding={0}
            width={props.size === 'lg' ? '$button/size/lg' : '$button/size/md'}
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
          >
            {formattedChildren}
          </BaseToggleFrame>
        );
      })
      .otherwise(() => null)
  );
});
