// THIS IS AN AUTOGENERATED FILE. REGENERATE USING THE TOKENS2CODE FIGMA PLUGIN

import { linkVariablesToTokens } from '../variables';

const coreTokensColorRaw = {
  'accent/1': '#f2ecfc',
  'accent/2': '#e5d8f8',
  'accent/3': '#d8c5f5',
  'accent/4': '#ccb1f1',
  'accent/5': '#bf9eee',
  'accent/6': '#b28aea',
  'accent/7': '#a577e7',
  'accent/8': '#9863e3',
  'accent/9': '#7a4fb6',
  'accent/10': '#5b3b88',
  'accent/11': '#3d285b',
  'accent/12': '#1e142d',
  'black/alpha-0': 'rgba(0, 0, 0, 0.0000)',
  'black/alpha-50': 'rgba(0, 0, 0, 0.5000)',
  'black/alpha-60': 'rgba(0, 0, 0, 0.6000)',
  'black/alpha-100': '#000000',
  'danger/1': '#fef2f2',
  'danger/2': '#fee2e2',
  'danger/3': '#fecaca',
  'danger/4': '#fca5a5',
  'danger/5': '#f87171',
  'danger/6': '#ef4444',
  'danger/7': '#dc2626',
  'danger/8': '#b91c1c',
  'danger/9': '#991b1b',
  'danger/10': '#7f1d1d',
  'danger/11': '#4f1111',
  'danger/12': '#350a0a',
  'info/1': '#f0f9ff',
  'info/2': '#e0f2fe',
  'info/3': '#bae6fd',
  'info/4': '#7dd3fc',
  'info/5': '#38bdf8',
  'info/6': '#0ea5e9',
  'info/7': '#0284c7',
  'info/8': '#0369a1',
  'info/9': '#075985',
  'info/10': '#0b4465',
  'info/11': '#072a3f',
  'info/12': '#031823',
  'neutral/1': '#f6faff',
  'neutral/2': '#e1e4e9',
  'neutral/3': '#c2cad3',
  'neutral/4': '#aeb5bd',
  'neutral/5': '#9aa0a7',
  'neutral/6': '#868b92',
  'neutral/7': '#71757c',
  'neutral/8': '#5d6066',
  'neutral/9': '#494b50',
  'neutral/10': '#34353a',
  'neutral/11': '#202024',
  'neutral/12': '#121213',
  'primary/1': '#dafffb',
  'primary/2': '#b4fff6',
  'primary/3': '#8ffff2',
  'primary/4': '#69ffed',
  'primary/5': '#44ffe9',
  'primary/6': '#3be8d6',
  'primary/7': '#32d1c4',
  'primary/8': '#29bab1',
  'primary/9': '#20a39f',
  'primary/10': '#178c8c',
  'primary/11': '#0f5d5d',
  'primary/12': '#082f2f',
  'secondary/1': '#e4e9ee',
  'secondary/2': '#dce1e5',
  'secondary/3': '#c9cdd1',
  'secondary/4': '#b5b8bd',
  'secondary/5': '#8e9095',
  'secondary/6': '#7c7f84',
  'secondary/7': '#6b6d72',
  'secondary/8': '#5a5b60',
  'secondary/9': '#49494e',
  'secondary/10': '#37383d',
  'secondary/11': '#26262b',
  'secondary/12': '#1b1b1d',
  'success/1': '#dcfce7',
  'success/2': '#bbf7d0',
  'success/3': '#86efac',
  'success/4': '#4ade80',
  'success/5': '#22c55e',
  'success/6': '#16a34a',
  'success/7': '#149142',
  'success/8': '#117f3a',
  'success/9': '#0f6d31',
  'success/10': '#0c5b29',
  'success/11': '#0a4821',
  'success/12': '#073619',
  'tertiary/1': '#ffece0',
  'tertiary/2': '#ffdac1',
  'tertiary/3': '#ffc7a2',
  'tertiary/4': '#ffb482',
  'tertiary/5': '#ffa163',
  'tertiary/6': '#ff8f44',
  'tertiary/7': '#ff7c25',
  'tertiary/8': '#ee6516',
  'tertiary/9': '#dc4e07',
  'tertiary/10': '#a53b05',
  'tertiary/11': '#6e2704',
  'tertiary/12': '#371402',
  'warning/1': '#fffbeb',
  'warning/2': '#fef3c7',
  'warning/3': '#fde68a',
  'warning/4': '#fcd34d',
  'warning/5': '#fbbf24',
  'warning/6': '#f59e0b',
  'warning/7': '#d97706',
  'warning/8': '#b45309',
  'warning/9': '#92400e',
  'warning/10': '#75330b',
  'warning/11': '#582608',
  'warning/12': '#3a1a06',
  'white/alpha-0': 'rgba(255, 255, 255, 0.0000)',
  'white/alpha-50': 'rgba(255, 255, 255, 0.5000)',
  'white/alpha-60': 'rgba(255, 255, 255, 0.6000)',
  'white/alpha-100': '#ffffff',
} as const;
export const coreTokensColor = linkVariablesToTokens(coreTokensColorRaw, 'color');

const coreTokensRadiusRaw = {
  '0': 0,
  '1': 2,
  '2': 4,
  '3': 6,
  '4': 8,
  '5': 10,
  '6': 12,
  '7': 14,
  '8': 16,
  '9': 18,
  '10': 20,
  '12': 24,
  '14': 28,
  '1000': 1000,
} as const;
export const coreTokensRadius = linkVariablesToTokens(coreTokensRadiusRaw, 'radius');

const coreTokensSizeRaw = {
  '0': 0,
  '1': 20,
  '2': 28,
  '3': 36,
  '4': 44,
  '5': 56,
  '6': 64,
  '7': 72,
  '8': 88,
  '9': 96,
  '10': 112,
  '11': 124,
  '12': 144,
  '13': 164,
  '14': 184,
  '15': 204,
  '16': 224,
  '17': 224,
  '18': 244,
  '19': 264,
  '20': 280,
  '0-5': 8,
  '0-12-5': 1,
  '0-25': 2,
  '0-75': 16,
  '0_percent': '0%',
  '1-5': 24,
  '2-5': 32,
  '3-5': 40,
  '4-5': 48,
  '12-5_percent': '12.5%',
  '25_percent': '25%',
  '37-5_percent': '37.5%',
  '50_percent': '50%',
  '62-5_percent': '62.5%',
  '75_percent': '75%',
  '87-5_percent': '87.5%',
  '100_percent': '100%',
} as const;
export const coreTokensSize = linkVariablesToTokens(coreTokensSizeRaw, 'size');

const coreTokensSpaceRaw = {
  '0': 0,
  '1': 2,
  '2': 8,
  '3': 12,
  '4': 18,
  '5': 24,
  '6': 26,
  '7': 28,
  '8': 32,
  '9': 36,
  '10': 40,
  '11': 44,
  '12': 48,
  '14': 56,
  '16': 64,
  '20': 80,
  '24': 96,
  '28': 112,
  '32': 128,
  '36': 144,
  '40': 160,
  '44': 176,
  '48': 192,
  '52': 208,
  '56': 224,
  '60': 240,
  '64': 256,
  '72': 288,
  '80': 384,
  '0-5': 1,
  '0-25': 0.5,
  '0-75': 1.5,
  '1-5': 4,
  '2-5': 10,
  '3-5': 16,
  '4-5': 20,
} as const;
export const coreTokensSpace = linkVariablesToTokens(coreTokensSpaceRaw, 'space');
