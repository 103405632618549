import { useEffect } from 'react';
import { useRefreshToken } from 'app/api/internal/useRefreshToken';
import { useAppSelector } from 'app/store';

const REFRESH_TIME_BUFFER_IN_MILLISECONDS = 60000; // Refresh 1 minute before expiry

/** Hook to automatically refresh the token just before it expires. */
export const useAutomaticRefreshToken = () => {
  // const auth0Config = useAppSelector((state) => state.auth.auth0Config);
  const tokenInfo = useAppSelector((state) => state.auth.tokenInfo);
  const [refreshToken, releaseLock] = useRefreshToken();

  useEffect(() => {
    if (!tokenInfo.expiry) {
      return;
    }
    // Time until access token expires in milliseconds
    const expiresIn = tokenInfo.expiry - Date.now();
    // Refresh 1 minute before expiry. If access token expiry is <= 1 minute, then refresh half that time instead (minimum 1s).
    // The token expiry should not be configured to be less than (or close to) a minute realistically.
    const refetchTime = Math.max(
      expiresIn > REFRESH_TIME_BUFFER_IN_MILLISECONDS ? expiresIn - REFRESH_TIME_BUFFER_IN_MILLISECONDS : expiresIn / 2,
      1000
    );

    const refreshTimeout = setTimeout(() => {
      // Errors will be handled by the interceptor
      void refreshToken();
    }, refetchTime);
    return () => {
      clearTimeout(refreshTimeout);
      releaseLock();
    };
  }, [tokenInfo.expiry, refreshToken, releaseLock]);
};
