import { Dialog, Stack, styled, withStaticProperties, XStack, YStack } from 'tamagui';
import type { ReactNode } from 'react';
import type { ButtonProps } from '../Button';
import { Button } from '../Button';
import { Elevation } from '../Elevation';
import { Text } from '../Text';
import { Loader } from '../Loader';

// Props
export interface ModalProps {
  key?: string;
  children: ReactNode;
  open: boolean;
  title: string;
  buttons: (ButtonProps & { label: string; id?: string })[];
  setModalOpen: (a: boolean) => void;
  testID?: string;
  width?: number;
  isLoading?: boolean;
  /**
   * Clicking outside modal will close the modal
   * Default not enabled
   */
  dismissible?: boolean;
}

// Modal Base Frame
const ModalBaseFrame = styled(Dialog, {
  name: 'ModalBaseFrame',
  variants: {
    modalOpen: {
      true: {
        open: true,
      },
      false: {
        open: false,
      },
    },
  },
});

export const ModalDescription = styled(Text, {
  name: 'ModalDescription',
  color: '$foreground/surface-subdued',
  variant: 'bodySmall',
});

//Modal component from extended base components
const ModalBase = withStaticProperties(ModalBaseFrame, {
  DescriptionText: ModalDescription,
  Button,
});

export const Modal = ({
  key,
  children,
  open,
  title,
  setModalOpen,
  buttons,
  testID,
  isLoading = false,
  dismissible = true,
  width,
}: ModalProps) => {
  return (
    <ModalBase
      modal
      modalOpen={open}
      onOpenChange={(event) => {
        if (!dismissible) {
          return;
        }
        setModalOpen(event);
      }}
    >
      <ModalBase.Portal>
        <ModalBase.Overlay
          backgroundColor="$other/black-alpha-50"
          key={`${key}-overlay`}
          animation="quick"
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        />
        <ModalBase.Content
          testID={testID}
          padding={0}
          alignItems="center"
          backgroundColor="$background/transparent"
          elevate
          marginHorizontal="$space.2xl"
          width={width ?? 360}
          maxWidth="90%"
          onOpenAutoFocus={(e: Event) => {
            // Stop auto focus on open
            e.preventDefault();
          }}
        >
          <Elevation
            level={4}
            shadow="soft"
            key={`${key}-content`}
            animateOnly={['transform', 'opacity']}
            animation="100ms"
            enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
            exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
            borderRadius="$surface/radius/surface-radius"
            backgroundColor="$background/surface"
            width="100%"
            maxHeight="80vh"
            padding="$xl"
          >
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <ModalBase.Title tag="h4" fontSize="$lg" lineHeight="$lg" fontWeight="$lg">
                  {title}
                </ModalBase.Title>
                <YStack marginTop="$sm" padding="$space.xxs" flexShrink={1} style={{ overflowY: 'auto' }}>
                  {children}
                </YStack>
                <XStack width="100%" marginTop="$lg" flexWrap="wrap" gap="$space.sm" justifyContent="space-between">
                  {buttons.map(({ label, id, ...buttonProps }, index) => (
                    <Stack flex={1} flexBasis={120} minWidth="auto" maxWidth="100%" key={id ?? index}>
                      <Button
                        size="md"
                        focusable={false}
                        testID={`${testID || 'modal'}-button-${index}`}
                        {...buttonProps}
                      >
                        {label}
                      </Button>
                    </Stack>
                  ))}
                </XStack>
              </>
            )}
          </Elevation>
        </ModalBase.Content>
      </ModalBase.Portal>
    </ModalBase>
  );
};
