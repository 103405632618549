'use client';

import { Spinner, Stack, XStack } from 'tamagui';
import { Trash2 } from '@tamagui/lucide-icons';
import { useState } from 'react';
import { InformationTable } from '../Tables/InformationTable';
import { IconButton } from '../IconButton';
import { Text } from '../Text';
import type { UploadedFile } from './types';

interface DisplayUploadedDocProps {
  testId?: string;
  /**
   * list of name ids to display
   */
  files: UploadedFile[];
  /**
   * Category for callback when deleted doc
   */
  category: string;
  /**
   * On delete call back to remove document
   */
  onDeleteDoc?: (fileId: string) => Promise<void> | void;
  /**
   * Whether file upload is disabled
   */
  disabled?: boolean;
}

export const DisplayUploadedDoc = ({
  testId = 'DisplayUploadedDoc',
  files,
  onDeleteDoc,
  disabled,
}: DisplayUploadedDocProps) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  return (
    <InformationTable
      column1FlexBasis={100}
      background="transparent"
      testID={testId}
      rows={files.map((file: UploadedFile) => {
        return {
          data: [
            <XStack flex={1} key={file.id} justifyContent="space-between" alignItems="center">
              <Stack>
                <Text variant="bodySmall">{file.name}</Text>
              </Stack>
              <Stack>
                {onDeleteDoc ? (
                  <Stack cursor={disabled ? undefined : 'pointer'} disabled={disabled}>
                    {isDeleting ? (
                      <Spinner size="small" color="$foreground/primary-default" />
                    ) : (
                      <IconButton
                        key="deleteIcon"
                        tag="button"
                        role="button"
                        testID={`delete-document-${file.name}`}
                        icon={<Trash2 />}
                        onPress={async () => {
                          setIsDeleting(true);
                          await onDeleteDoc(file.id);
                          setIsDeleting(false);
                        }}
                        borderColor="$icon/app"
                        variant="icon-only"
                        size="md"
                      />
                    )}
                  </Stack>
                ) : undefined}
              </Stack>
            </XStack>,
          ],
        };
      })}
    />
  );
};
