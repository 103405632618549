'use client';

import { z } from 'zod';
import { Fieldset } from 'tamagui';
import type { TypeaheadFieldProps } from '../TypeaheadField';
import { TypeaheadField } from '../TypeaheadField';

export const createBusinessLookupFieldItemSchema = (params?: z.RawCreateParams) =>
  z.object(
    {
      abn: z.string(),
      businessName: z.string().optional(),
      businessNames: z.array(z.string()),
      name: z.string(),
      state: z.string().optional(),
      postalCode: z.string().optional(),
      type: z.string().nullable(),
      preferredBusinessName: z.string(),
    },
    params ?? {
      required_error: 'Please select a business',
    }
  );

export type BusinessLookupFieldSchema = ReturnType<typeof createBusinessLookupFieldItemSchema>;
export type BusinessLookupFieldItem = z.infer<BusinessLookupFieldSchema>;
export type BusinessLookupFieldProps = TypeaheadFieldProps<BusinessLookupFieldItem>;

export function BusinessLookupField(props: BusinessLookupFieldProps): JSX.Element {
  return (
    <Fieldset>
      <TypeaheadField<BusinessLookupFieldItem> {...props} />
    </Fieldset>
  );
}
