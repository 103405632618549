// THIS IS AN AUTOGENERATED FILE. REGENERATE USING THE TOKENS2CODE FIGMA PLUGIN

import { linkVariablesToTokens } from '../variables';

const coreTokensColorRaw = {
  'accent/1': '#f8f7fc',
  'accent/2': '#eeecf9',
  'accent/3': '#c1bae8',
  'accent/4': '#9b90d5',
  'accent/5': '#8377c5',
  'accent/6': '#665aa7',
  'accent/7': '#514785',
  'accent/8': '#3c3564',
  'accent/9': '#282343',
  'accent/10': '#ffffff',
  'accent/11': '#ffffff',
  'accent/12': '#ffffff',
  'black/alpha-0': 'rgba(0, 0, 0, 0.0000)',
  'black/alpha-50': 'rgba(0, 0, 0, 0.5000)',
  'black/alpha-60': 'rgba(0, 0, 0, 0.6000)',
  'black/alpha-100': '#000000',
  'danger/1': '#fef6f6',
  'danger/2': '#fce8e8',
  'danger/3': '#f7bbba',
  'danger/4': '#ed7a78',
  'danger/5': '#d6403d',
  'danger/6': '#cc3633',
  'danger/7': '#a32b29',
  'danger/8': '#7a201f',
  'danger/9': '#521514',
  'danger/10': '#ffffff',
  'danger/11': '#ffffff',
  'danger/12': '#ffffff',
  'info/1': '#f6fdfe',
  'info/2': '#edfbfd',
  'info/3': '#beecf4',
  'info/4': '#70ccdb',
  'info/5': '#00a0b9',
  'info/6': '#007f94',
  'info/7': '#006170',
  'info/8': '#004f5c',
  'info/9': '#003f47',
  'info/10': '#ffffff',
  'info/11': '#ffffff',
  'info/12': '#ffffff',
  'neutral/1': '#f9fafa',
  'neutral/2': '#f4f5f6',
  'neutral/3': '#e3e6e8',
  'neutral/4': '#b8bec5',
  'neutral/5': '#99a2ac',
  'neutral/6': '#818c98',
  'neutral/7': '#65707b',
  'neutral/8': '#505962',
  'neutral/9': '#394046',
  'neutral/10': '#000000',
  'neutral/11': '#ffffff',
  'neutral/12': '#ffffff',
  'primary/1': '#f5fcff',
  'primary/2': '#cff0fc',
  'primary/3': '#a3def5',
  'primary/4': '#81c9e4',
  'primary/5': '#4da9cb',
  'primary/6': '#237fa4',
  'primary/7': '#0f688a',
  'primary/8': '#054861',
  'primary/9': '#00384d',
  'primary/10': '#ffffff',
  'primary/11': '#ffffff',
  'primary/12': '#ffffff',
  'secondary/1': '#f7fdfd',
  'secondary/2': '#e2f8f6',
  'secondary/3': '#b9e9e6',
  'secondary/4': '#99d9d5',
  'secondary/5': '#79c8c3',
  'secondary/6': '#5bb3ae',
  'secondary/7': '#31817c',
  'secondary/8': '#1e625c',
  'secondary/9': '#0d3f3c',
  'secondary/10': '#ffffff',
  'secondary/11': '#ffffff',
  'secondary/12': '#ffffff',
  'success/1': '#eefbfa',
  'success/2': '#e7f9f7',
  'success/3': '#b0e8e3',
  'success/4': '#66ccc2',
  'success/5': '#00a796',
  'success/6': '#008574',
  'success/7': '#007065',
  'success/8': '#005c52',
  'success/9': '#003d38',
  'success/10': '#ffffff',
  'success/11': '#ffffff',
  'success/12': '#ffffff',
  'tertiary/1': '#ffffff',
  'tertiary/2': '#ffffff',
  'tertiary/3': '#ffffff',
  'tertiary/4': '#ffffff',
  'tertiary/5': '#ffffff',
  'tertiary/6': '#ffffff',
  'tertiary/7': '#ffffff',
  'tertiary/8': '#ffffff',
  'tertiary/9': '#ffffff',
  'tertiary/10': '#ffffff',
  'tertiary/11': '#ffffff',
  'tertiary/12': '#ffffff',
  'warning/1': '#fff5eb',
  'warning/2': '#fff1e0',
  'warning/3': '#ffd4a3',
  'warning/4': '#ffb766',
  'warning/5': '#f9921d',
  'warning/6': '#df7b06',
  'warning/7': '#b35f00',
  'warning/8': '#804400',
  'warning/9': '#5c3100',
  'warning/10': '#ffffff',
  'warning/11': '#ffffff',
  'warning/12': '#ffffff',
  'white/alpha-0': 'rgba(255, 255, 255, 0.0000)',
  'white/alpha-50': 'rgba(255, 255, 255, 0.5000)',
  'white/alpha-60': 'rgba(255, 255, 255, 0.6000)',
  'white/alpha-100': '#ffffff',
} as const;
export const coreTokensColor = linkVariablesToTokens(coreTokensColorRaw, 'color');

const coreTokensRadiusRaw = {
  '0': 0,
  '1': 2,
  '2': 4,
  '3': 6,
  '4': 8,
  '5': 10,
  '6': 12,
  '7': 14,
  '8': 16,
  '9': 18,
  '10': 20,
  '12': 24,
  '14': 28,
  '1000': 1000,
} as const;
export const coreTokensRadius = linkVariablesToTokens(coreTokensRadiusRaw, 'radius');

const coreTokensSizeRaw = {
  '0': 0,
  '1': 20,
  '2': 28,
  '3': 36,
  '4': 44,
  '5': 56,
  '6': 64,
  '7': 72,
  '8': 88,
  '9': 96,
  '10': 112,
  '11': 124,
  '12': 144,
  '13': 164,
  '14': 184,
  '15': 204,
  '16': 224,
  '17': 224,
  '18': 256,
  '19': 264,
  '20': 280,
  '0-5': 8,
  '0-12-5': 1,
  '0-25': 2,
  '0-75': 16,
  '0_percent': '0%',
  '1-5': 24,
  '2-5': 32,
  '3-5': 40,
  '4-5': 48,
  '12-5_percent': '12.5%',
  '25_percent': '25%',
  '37-5_percent': '37.5%',
  '50_percent': '50%',
  '62-5_percent': '62.5%',
  '75_percent': '75%',
  '87-5_percent': '87.5%',
  '100_percent': '100%',
} as const;
export const coreTokensSize = linkVariablesToTokens(coreTokensSizeRaw, 'size');

const coreTokensSpaceRaw = {
  '0': 0,
  '1': 2,
  '2': 8,
  '3': 12,
  '4': 18,
  '5': 24,
  '6': 24,
  '7': 28,
  '8': 32,
  '9': 36,
  '10': 40,
  '11': 44,
  '12': 48,
  '14': 56,
  '16': 64,
  '20': 80,
  '24': 96,
  '28': 112,
  '32': 128,
  '36': 144,
  '40': 160,
  '44': 176,
  '48': 192,
  '52': 208,
  '56': 224,
  '60': 240,
  '64': 256,
  '72': 288,
  '80': 384,
  '0-5': 1,
  '0-25': 0.5,
  '0-75': 1.5,
  '1-5': 4,
  '2-5': 10,
  '3-5': 16,
  '4-5': 20,
} as const;
export const coreTokensSpace = linkVariablesToTokens(coreTokensSpaceRaw, 'space');
