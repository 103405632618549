import { tokenInfoNoInterceptor } from 'app/api/no-interceptor/tokenInfo';
import { resetTokenInfo, setTokenInfo } from 'app/features/auth/authSlice';
import type { UpdateAuthInfoParams } from './types';

/**
 * Function that updates the auth info in the store
 */
export const updateAuthInfo = async <T>({ dispatch }: UpdateAuthInfoParams<T>) => {
  // Get token info from cookies
  const tokenInfo = await tokenInfoNoInterceptor();
  if (tokenInfo) {
    dispatch(setTokenInfo(tokenInfo));
  } else {
    dispatch(resetTokenInfo());
  }
};
