import type { GetProps, GetThemeValueForKey } from '@tamagui/web';
import { Stack, createStyledContext, styled } from '@tamagui/web';
import type { ReactElement } from 'react';
import { cloneElement } from 'react';
import { YStack } from 'tamagui';
import type { IconProps } from '@cxnpl/ui/icons';
import type { TextProps } from '../Text';
import { Text } from '../Text';

type IconButtonColorMode = 'primary' | 'secondary' | 'tertiary';
type IconButtonColorAllVariant = IconButtonColorMode | 'disabled';
type IconButtonVariant = 'filled' | 'outlined' | 'icon-only';
type IconButtonSize = 'md' | 'lg' | 'xl';

interface IconButtonContextValues {
  mode?: IconButtonColorMode;
  variant?: IconButtonVariant;
  size?: IconButtonSize;
  disabled?: boolean;
}

// All these values will be passed into child components of IconButtonBase
const IconButtonContext = createStyledContext<IconButtonContextValues>({
  mode: 'primary',
  variant: 'filled',
  size: 'lg',
  disabled: false,
});

interface IconButtonVariantProps extends GetProps<typeof Stack> {
  color: GetThemeValueForKey<'color'>;
}

const ICON_BUTTON_STYLES: {
  [mode in IconButtonColorAllVariant]: {
    [variant in IconButtonVariant]: IconButtonVariantProps;
  };
} = {
  primary: {
    filled: {
      color: '$button/color/button-primary-fg',
      backgroundColor: '$button/color/button-primary-bg-default',
      borderColor: '$button/color/button-primary-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-primary-bg-hover',
        borderColor: '$button/color/button-primary-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-primary-bg-hover',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-primary-bg-press',
        borderColor: '$button/color/button-primary-border-hover',
      },
    },
    outlined: {
      color: '$button/color/button-primary-text',
      backgroundColor: '$background/transparent',
      borderColor: '$button/color/button-outlined-primary-border',
      borderWidth: 1,
      hoverStyle: {
        backgroundColor: '$button/color/button-outlined-primary-bg-hover',
        borderColor: '$button/color/button-outlined-primary-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-outlined-primary-bg-hover',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-outlined-primary-bg-press',
        borderColor: '$button/color/button-outlined-primary-border-press',
      },
    },
    'icon-only': {
      color: '$button/color/button-primary-text',
      backgroundColor: '$background/transparent',
      hoverStyle: {
        backgroundColor: '$background/transparent',
      },
      focusStyle: {
        backgroundColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$background/transparent',
      },
    },
  },
  secondary: {
    filled: {
      color: '$button/color/button-secondary-fg',
      backgroundColor: '$button/color/button-secondary-bg-default',
      borderColor: '$button/color/button-secondary-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-secondary-bg-hover',
        borderColor: '$button/color/button-secondary-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-secondary-bg-hover',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-secondary-bg-press',
        borderColor: '$button/color/button-secondary-border-press',
      },
    },
    outlined: {
      color: '$button/color/button-secondary-text',
      backgroundColor: '$button/color/button-outlined-secondary-bg',
      borderColor: '$button/color/button-outlined-secondary-border',
      borderWidth: 1,
      hoverStyle: {
        backgroundColor: '$button/color/button-outlined-secondary-bg-hover',
        borderColor: '$button/color/button-outlined-secondary-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-outlined-secondary-bg-hover',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-outlined-secondary-bg-press',
        borderColor: '$button/color/button-outlined-secondary-border-press',
      },
    },
    'icon-only': {
      color: '$button/color/button-secondary-text',
      backgroundColor: '$background/transparent',
      hoverStyle: {
        backgroundColor: '$background/transparent',
      },
      focusStyle: {
        backgroundColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$background/transparent',
      },
    },
  },
  tertiary: {
    filled: {
      color: '$button/color/button-tertiary-fg',
      backgroundColor: '$button/color/button-tertiary-bg-default',
      borderColor: '$button/color/button-tertiary-border',
      hoverStyle: {
        backgroundColor: '$button/color/button-tertiary-bg-hover',
        borderColor: '$button/color/button-tertiary-border-hover',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-tertiary-bg-hover',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-tertiary-bg-press',
        borderColor: '$button/color/button-tertiary-border-press',
      },
    },
    outlined: {
      color: '$button/color/button-tertiary-text',
      backgroundColor: '$button/color/button-outlined-tertiary-bg',
      borderColor: '$button/color/button-outlined-tertiary-border',
      borderWidth: 1,
      hoverStyle: {
        backgroundColor: '$button/color/button-outlined-tertiary-bg',
        borderColor: '$button/color/button-outlined-tertiary-border-hover',
      },
      focusStyle: {
        backgroundColor: '$background/transparent',
        borderColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-outlined-tertiary-bg-press',
        borderColor: '$button/color/button-outlined-tertiary-border-press',
      },
    },
    'icon-only': {
      color: '$button/color/button-tertiary-text',
      backgroundColor: '$background/transparent',
      hoverStyle: {
        backgroundColor: '$background/transparent',
      },
      focusStyle: {
        backgroundColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$background/transparent',
      },
    },
  },
  disabled: {
    filled: {
      color: '$button/color/button-disabled-fg',
      backgroundColor: '$button/color/button-disabled-bg',
      hoverStyle: {
        backgroundColor: '$button/color/button-disabled-bg',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-disabled-bg',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-disabled-bg',
      },
    },
    outlined: {
      color: '$button/color/button-disabled-fg',
      backgroundColor: '$button/color/button-disabled-bg',
      borderWidth: 1,
      borderColor: '$border/disabled',
      hoverStyle: {
        backgroundColor: '$button/color/button-disabled-bg',
        borderColor: '$button/color/button-disabled-bg',
      },
      focusStyle: {
        backgroundColor: '$button/color/button-disabled-bg',
        borderColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$button/color/button-disabled-bg',
        borderColor: '$button/color/button-disabled-bg',
      },
    },
    'icon-only': {
      color: '$icon/disabled',
      backgroundColor: '$background/transparent',
      hoverStyle: {
        backgroundColor: '$background/transparent',
      },
      focusStyle: {
        backgroundColor: '$background/transparent',
        outlineWidth: 2,
        outlineStyle: 'solid',
        outlineColor: '$button/color/button-focus-border',
      },
      pressStyle: {
        backgroundColor: '$background/transparent',
      },
    },
  },
} as const;

const IconButtonBase = styled(Stack, {
  name: 'Button',
  context: IconButtonContext,

  // Default styling from Tamagui's Button
  tag: 'button',
  role: 'button',
  focusable: true,
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  cursor: 'pointer',

  borderWidth: 0,
  borderRadius: '$fab/radius/fab-radius',

  variants: {
    mode: { primary: {}, secondary: {}, tertiary: {} },
    variant: {
      filled: (_, { props }) => {
        // These are props which passed into the component
        const { mode = 'primary', disabled } = props as GetProps<typeof Stack> & IconButtonContextValues;
        const colorVariant: IconButtonColorAllVariant = disabled ? 'disabled' : mode;
        return ICON_BUTTON_STYLES[colorVariant].filled;
      },
      outlined: (_, { props }) => {
        // These are props which passed into the component
        const { mode = 'primary', disabled } = props as GetProps<typeof Stack> & IconButtonContextValues;
        const colorVariant: IconButtonColorAllVariant = disabled ? 'disabled' : mode;
        return ICON_BUTTON_STYLES[colorVariant].outlined;
      },
      'icon-only': (_, { props }) => {
        // These are props which passed into the component
        const { mode = 'primary', disabled } = props as GetProps<typeof Stack> & IconButtonContextValues;
        const colorVariant: IconButtonColorAllVariant = disabled ? 'disabled' : mode;
        return ICON_BUTTON_STYLES[colorVariant]['icon-only'];
      },
    },
    disabled: {
      true: {
        pointerEvents: 'none',
      },
    },
    size: {
      md: {
        padding: '$icon-button/space/md',
        height: '$icon-button/size/md',
        width: '$icon-button/size/md',
      },
      lg: {
        padding: '$icon-button/space/lg',
        height: '$icon-button/size/lg',
        width: '$icon-button/size/lg',
      },
      xl: {
        padding: '$icon-button/space/xl',
        height: '$icon-button/size/xl',
        width: '$icon-button/size/xl',
      },
    },
  },
} as const);

export interface IconButtonProps extends GetProps<typeof IconButtonBase> {
  icon: string | ReactElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- todo: resolve missing event typings
  onPress?: (event?: any) => void | Promise<void>;
  mode?: IconButtonContextValues['mode'];
  variant?: IconButtonContextValues['variant'];
  size?: IconButtonContextValues['size'];
  disabled?: boolean;
  testID?: string;
  iconProps?: IconProps;
}

export const IconButton = ({
  icon,
  mode = 'primary',
  variant = 'filled',
  size = 'lg',
  testID,
  disabled,
  /**
   * Override icon props defined by mode and variant, such as size and color
   */
  iconProps,
  ...props
}: IconButtonProps) => {
  const getColor = () => {
    const colorVariant = disabled ? 'disabled' : mode;
    return ICON_BUTTON_STYLES[colorVariant][variant].color;
  };
  return (
    <IconButtonBase
      testID={testID}
      mode={mode}
      variant={variant}
      size={size}
      disabled={disabled}
      {...props}
      justifyContent="center"
      alignItems="center"
    >
      {typeof icon === 'string' ? (
        <YStack>
          <Text variant="bodyMedium" color={getColor() as TextProps['color']}>
            {icon}
          </Text>
        </YStack>
      ) : (
        cloneElement(icon, {
          size: '$icon-button/size/icon',
          color: getColor(),
          ...iconProps,
        })
      )}
    </IconButtonBase>
  );
};
