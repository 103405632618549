/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  ChangePasswordBody,
  CodeDeliveryDetailsType,
  ConfirmAttrBody,
  ConfirmEmailBody,
  ConfirmForgotPasswordBody,
  ConfirmMobileBody,
  ConfirmationCodeResponse,
  CreateSignupConsents200,
  CreateSignupConsentsBody,
  CreateUserConsents200,
  CreateUserConsentsBody,
  ErrorMessage,
  ForgotPasswordBody,
  ForgotPasswordChallenged,
  ForgotPasswordV2Body,
  GetInviteByCode200,
  GetInviteByUserId200Item,
  GetSummary200,
  LoginUser200,
  LoginUserBody,
  RefreshToken200,
  RefreshTokenBody,
  RegisterUser200,
  RegisterUserBody,
  ResendCodeBody,
  ResponseToInvite200,
  ResponseToInviteBody,
  ReturnTypeTypeofdevicePublicInfoSchemaParse,
  ReturnTypeTypeofmarketingOptionPatchValidatorParse,
  RevokeWebAppSessionBody,
  SignOutUserDeviceBody,
  UpdateAttrBody,
  UpdateCurrentEntityBody,
  UpdatePersonalInfoBody,
  VerificationResponse,
  VerifyEmailBody,
  VerifyMFABody,
  VerifyMobileBody,
  VerifyPasswordBody,
  _CodeDeliveryDetails,
} from '../api.schemas';
import { customAxios } from '../../axios';

/**
 * Register consents or acknowledgement for a user.
 * @summary Register consents for a user
 */
export const createUserConsents = (createUserConsentsBody: CreateUserConsentsBody, signal?: AbortSignal) => {
  return customAxios<CreateUserConsents200>({
    url: `/user/acknowledgements-and-consents`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createUserConsentsBody,
    signal,
  });
};

export const getCreateUserConsentsMutationOptions = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserConsents>>,
    TError,
    { data: CreateUserConsentsBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUserConsents>>,
  TError,
  { data: CreateUserConsentsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUserConsents>>,
    { data: CreateUserConsentsBody }
  > = (props) => {
    const { data } = props ?? {};

    return createUserConsents(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserConsentsMutationResult = NonNullable<Awaited<ReturnType<typeof createUserConsents>>>;
export type CreateUserConsentsMutationBody = CreateUserConsentsBody;
export type CreateUserConsentsMutationError = ErrorMessage;

/**
 * @summary Register consents for a user
 */
export const useCreateUserConsents = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserConsents>>,
    TError,
    { data: CreateUserConsentsBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createUserConsents>>,
  TError,
  { data: CreateUserConsentsBody },
  TContext
> => {
  const mutationOptions = getCreateUserConsentsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get Information related to current user
 * @summary Get current user information
 */
export const getSummary = (signal?: AbortSignal) => {
  return customAxios<GetSummary200>({ url: `/user/summary`, method: 'GET', signal });
};

export const getGetSummaryQueryKey = () => {
  return [`/user/summary`] as const;
};

export const getGetSummaryQueryOptions = <TData = Awaited<ReturnType<typeof getSummary>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSummary>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSummaryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSummary>>> = ({ signal }) => getSummary(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSummary>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getSummary>>>;
export type GetSummaryQueryError = unknown;

export function useGetSummary<TData = Awaited<ReturnType<typeof getSummary>>, TError = unknown>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSummary>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getSummary>>, TError, TData>, 'initialData'>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSummary<TData = Awaited<ReturnType<typeof getSummary>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSummary>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getSummary>>, TError, TData>, 'initialData'>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSummary<TData = Awaited<ReturnType<typeof getSummary>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSummary>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get current user information
 */

export function useGetSummary<TData = Awaited<ReturnType<typeof getSummary>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSummary>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSummaryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update the current entity
 * @summary Update the current entity
 */
export const updateCurrentEntity = (updateCurrentEntityBody: UpdateCurrentEntityBody) => {
  return customAxios<void>({
    url: `/user/currentEntity`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateCurrentEntityBody,
  });
};

export const getUpdateCurrentEntityMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCurrentEntity>>,
    TError,
    { data: UpdateCurrentEntityBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCurrentEntity>>,
  TError,
  { data: UpdateCurrentEntityBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCurrentEntity>>,
    { data: UpdateCurrentEntityBody }
  > = (props) => {
    const { data } = props ?? {};

    return updateCurrentEntity(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCurrentEntityMutationResult = NonNullable<Awaited<ReturnType<typeof updateCurrentEntity>>>;
export type UpdateCurrentEntityMutationBody = UpdateCurrentEntityBody;
export type UpdateCurrentEntityMutationError = unknown;

/**
 * @summary Update the current entity
 */
export const useUpdateCurrentEntity = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCurrentEntity>>,
    TError,
    { data: UpdateCurrentEntityBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCurrentEntity>>,
  TError,
  { data: UpdateCurrentEntityBody },
  TContext
> => {
  const mutationOptions = getUpdateCurrentEntityMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update current user personal information
Requires stepup MFA
 * @summary Update current user personal information
 */
export const updatePersonalInfo = (updatePersonalInfoBody: UpdatePersonalInfoBody) => {
  return customAxios<void>({
    url: `/user/personal`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updatePersonalInfoBody,
  });
};

export const getUpdatePersonalInfoMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePersonalInfo>>,
    TError,
    { data: UpdatePersonalInfoBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePersonalInfo>>,
  TError,
  { data: UpdatePersonalInfoBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePersonalInfo>>,
    { data: UpdatePersonalInfoBody }
  > = (props) => {
    const { data } = props ?? {};

    return updatePersonalInfo(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePersonalInfoMutationResult = NonNullable<Awaited<ReturnType<typeof updatePersonalInfo>>>;
export type UpdatePersonalInfoMutationBody = UpdatePersonalInfoBody;
export type UpdatePersonalInfoMutationError = unknown;

/**
 * @summary Update current user personal information
 */
export const useUpdatePersonalInfo = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePersonalInfo>>,
    TError,
    { data: UpdatePersonalInfoBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePersonalInfo>>,
  TError,
  { data: UpdatePersonalInfoBody },
  TContext
> => {
  const mutationOptions = getUpdatePersonalInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Verifies the password
 * @summary verify password
 */
export const verifyPassword = (verifyPasswordBody: VerifyPasswordBody, signal?: AbortSignal) => {
  return customAxios<boolean>({
    url: `/user/verifyPassword`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: verifyPasswordBody,
    signal,
  });
};

export const getVerifyPasswordMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof verifyPassword>>,
    TError,
    { data: VerifyPasswordBody },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof verifyPassword>>, TError, { data: VerifyPasswordBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyPassword>>, { data: VerifyPasswordBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return verifyPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type VerifyPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof verifyPassword>>>;
export type VerifyPasswordMutationBody = VerifyPasswordBody;
export type VerifyPasswordMutationError = unknown;

/**
 * @summary verify password
 */
export const useVerifyPassword = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof verifyPassword>>,
    TError,
    { data: VerifyPasswordBody },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof verifyPassword>>, TError, { data: VerifyPasswordBody }, TContext> => {
  const mutationOptions = getVerifyPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Initiate the update of a user's email or mobile number.
Returns an mfa code that must be used to confirm the change.
 * @summary Update a user's email/mobile
 */
export const updateAttr = (updateAttrBody: UpdateAttrBody) => {
  return customAxios<CodeDeliveryDetailsType>({
    url: `/user/updateAttr`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateAttrBody,
  });
};

export const getUpdateAttrMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateAttr>>, TError, { data: UpdateAttrBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof updateAttr>>, TError, { data: UpdateAttrBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAttr>>, { data: UpdateAttrBody }> = (props) => {
    const { data } = props ?? {};

    return updateAttr(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAttrMutationResult = NonNullable<Awaited<ReturnType<typeof updateAttr>>>;
export type UpdateAttrMutationBody = UpdateAttrBody;
export type UpdateAttrMutationError = unknown;

/**
 * @summary Update a user's email/mobile
 */
export const useUpdateAttr = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateAttr>>, TError, { data: UpdateAttrBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof updateAttr>>, TError, { data: UpdateAttrBody }, TContext> => {
  const mutationOptions = getUpdateAttrMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Confirm a user's email or mobile number update.
 * @summary Confirm a user's email or mobile number update
 */
export const confirmAttr = (confirmAttrBody: ConfirmAttrBody) => {
  return customAxios<void>({
    url: `/user/confirmAttr`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: confirmAttrBody,
  });
};

export const getConfirmAttrMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof confirmAttr>>, TError, { data: ConfirmAttrBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof confirmAttr>>, TError, { data: ConfirmAttrBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof confirmAttr>>, { data: ConfirmAttrBody }> = (props) => {
    const { data } = props ?? {};

    return confirmAttr(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmAttrMutationResult = NonNullable<Awaited<ReturnType<typeof confirmAttr>>>;
export type ConfirmAttrMutationBody = ConfirmAttrBody;
export type ConfirmAttrMutationError = unknown;

/**
 * @summary Confirm a user's email or mobile number update
 */
export const useConfirmAttr = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof confirmAttr>>, TError, { data: ConfirmAttrBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof confirmAttr>>, TError, { data: ConfirmAttrBody }, TContext> => {
  const mutationOptions = getConfirmAttrMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Change Current user password
 * @deprecated
 * @summary Change current user password
 */
export const changePassword = (changePasswordBody: ChangePasswordBody) => {
  return customAxios<void>({
    url: `/user/changePassword`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: changePasswordBody,
  });
};

export const getChangePasswordMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changePassword>>,
    TError,
    { data: ChangePasswordBody },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof changePassword>>, TError, { data: ChangePasswordBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof changePassword>>, { data: ChangePasswordBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return changePassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof changePassword>>>;
export type ChangePasswordMutationBody = ChangePasswordBody;
export type ChangePasswordMutationError = unknown;

/**
 * @deprecated
 * @summary Change current user password
 */
export const useChangePassword = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changePassword>>,
    TError,
    { data: ChangePasswordBody },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof changePassword>>, TError, { data: ChangePasswordBody }, TContext> => {
  const mutationOptions = getChangePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update current user notifications settings
 * @summary Update current user notifications settings
 */
export const updateNotificationSettings = (
  returnTypeTypeofmarketingOptionPatchValidatorParse: ReturnTypeTypeofmarketingOptionPatchValidatorParse
) => {
  return customAxios<void>({
    url: `/user/notifications`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: returnTypeTypeofmarketingOptionPatchValidatorParse,
  });
};

export const getUpdateNotificationSettingsMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotificationSettings>>,
    TError,
    { data: ReturnTypeTypeofmarketingOptionPatchValidatorParse },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateNotificationSettings>>,
  TError,
  { data: ReturnTypeTypeofmarketingOptionPatchValidatorParse },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateNotificationSettings>>,
    { data: ReturnTypeTypeofmarketingOptionPatchValidatorParse }
  > = (props) => {
    const { data } = props ?? {};

    return updateNotificationSettings(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateNotificationSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateNotificationSettings>>
>;
export type UpdateNotificationSettingsMutationBody = ReturnTypeTypeofmarketingOptionPatchValidatorParse;
export type UpdateNotificationSettingsMutationError = unknown;

/**
 * @summary Update current user notifications settings
 */
export const useUpdateNotificationSettings = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotificationSettings>>,
    TError,
    { data: ReturnTypeTypeofmarketingOptionPatchValidatorParse },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateNotificationSettings>>,
  TError,
  { data: ReturnTypeTypeofmarketingOptionPatchValidatorParse },
  TContext
> => {
  const mutationOptions = getUpdateNotificationSettingsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get list of current user devices
 * @summary Get list of current user devices
 */
export const getUserDevices = (signal?: AbortSignal) => {
  return customAxios<ReturnTypeTypeofdevicePublicInfoSchemaParse[]>({ url: `/user/devices`, method: 'GET', signal });
};

export const getGetUserDevicesQueryKey = () => {
  return [`/user/devices`] as const;
};

export const getGetUserDevicesQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserDevices>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDevices>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserDevicesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDevices>>> = ({ signal }) => getUserDevices(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserDevices>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetUserDevicesQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDevices>>>;
export type GetUserDevicesQueryError = unknown;

export function useGetUserDevices<TData = Awaited<ReturnType<typeof getUserDevices>>, TError = unknown>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDevices>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getUserDevices>>, TError, TData>, 'initialData'>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetUserDevices<TData = Awaited<ReturnType<typeof getUserDevices>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDevices>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getUserDevices>>, TError, TData>, 'initialData'>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetUserDevices<TData = Awaited<ReturnType<typeof getUserDevices>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDevices>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get list of current user devices
 */

export function useGetUserDevices<TData = Awaited<ReturnType<typeof getUserDevices>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDevices>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetUserDevicesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Sign out current user
 * @summary Sign out current user
 */
export const signOutUserDevice = (deviceId: string, signOutUserDeviceBody: SignOutUserDeviceBody) => {
  return customAxios<void>({
    url: `/user/devices/${deviceId}/signOut`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: signOutUserDeviceBody,
  });
};

export const getSignOutUserDeviceMutationOptions = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signOutUserDevice>>,
    TError,
    { deviceId: string; data: SignOutUserDeviceBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof signOutUserDevice>>,
  TError,
  { deviceId: string; data: SignOutUserDeviceBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof signOutUserDevice>>,
    { deviceId: string; data: SignOutUserDeviceBody }
  > = (props) => {
    const { deviceId, data } = props ?? {};

    return signOutUserDevice(deviceId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignOutUserDeviceMutationResult = NonNullable<Awaited<ReturnType<typeof signOutUserDevice>>>;
export type SignOutUserDeviceMutationBody = SignOutUserDeviceBody;
export type SignOutUserDeviceMutationError = ErrorMessage;

/**
 * @summary Sign out current user
 */
export const useSignOutUserDevice = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signOutUserDevice>>,
    TError,
    { deviceId: string; data: SignOutUserDeviceBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof signOutUserDevice>>,
  TError,
  { deviceId: string; data: SignOutUserDeviceBody },
  TContext
> => {
  const mutationOptions = getSignOutUserDeviceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete a user device
 * @summary Delete user device
 */
export const deleteUserDevice = (deviceId: string) => {
  return customAxios<void>({ url: `/user/devices/${deviceId}`, method: 'DELETE' });
};

export const getDeleteUserDeviceMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteUserDevice>>, TError, { deviceId: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteUserDevice>>, TError, { deviceId: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserDevice>>, { deviceId: string }> = (props) => {
    const { deviceId } = props ?? {};

    return deleteUserDevice(deviceId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserDeviceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserDevice>>>;

export type DeleteUserDeviceMutationError = unknown;

/**
 * @summary Delete user device
 */
export const useDeleteUserDevice = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteUserDevice>>, TError, { deviceId: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteUserDevice>>, TError, { deviceId: string }, TContext> => {
  const mutationOptions = getDeleteUserDeviceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets all non-revoked invites belonging to the user.
 * @summary Get all non-revoked invites belonging to the user
 */
export const getInviteByUserId = (signal?: AbortSignal) => {
  return customAxios<GetInviteByUserId200Item[]>({ url: `/user/inviteV2`, method: 'GET', signal });
};

export const getGetInviteByUserIdQueryKey = () => {
  return [`/user/inviteV2`] as const;
};

export const getGetInviteByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getInviteByUserId>>,
  TError = void,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteByUserId>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInviteByUserIdQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInviteByUserId>>> = ({ signal }) =>
    getInviteByUserId(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInviteByUserId>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetInviteByUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getInviteByUserId>>>;
export type GetInviteByUserIdQueryError = void;

export function useGetInviteByUserId<TData = Awaited<ReturnType<typeof getInviteByUserId>>, TError = void>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteByUserId>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getInviteByUserId>>, TError, TData>, 'initialData'>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetInviteByUserId<TData = Awaited<ReturnType<typeof getInviteByUserId>>, TError = void>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteByUserId>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getInviteByUserId>>, TError, TData>, 'initialData'>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetInviteByUserId<TData = Awaited<ReturnType<typeof getInviteByUserId>>, TError = void>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteByUserId>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all non-revoked invites belonging to the user
 */

export function useGetInviteByUserId<TData = Awaited<ReturnType<typeof getInviteByUserId>>, TError = void>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteByUserId>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetInviteByUserIdQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Gets an invite by the invite id. It also returns all relevant information attached to the invite
 * @summary Get invite by invite id
 */
export const getInviteByCode = (inviteId: string, signal?: AbortSignal) => {
  return customAxios<GetInviteByCode200>({ url: `/user/inviteV2/${inviteId}`, method: 'GET', signal });
};

export const getGetInviteByCodeQueryKey = (inviteId: string) => {
  return [`/user/inviteV2/${inviteId}`] as const;
};

export const getGetInviteByCodeQueryOptions = <TData = Awaited<ReturnType<typeof getInviteByCode>>, TError = void>(
  inviteId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteByCode>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInviteByCodeQueryKey(inviteId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInviteByCode>>> = ({ signal }) =>
    getInviteByCode(inviteId, signal);

  return { queryKey, queryFn, enabled: !!inviteId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInviteByCode>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetInviteByCodeQueryResult = NonNullable<Awaited<ReturnType<typeof getInviteByCode>>>;
export type GetInviteByCodeQueryError = void;

export function useGetInviteByCode<TData = Awaited<ReturnType<typeof getInviteByCode>>, TError = void>(
  inviteId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteByCode>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getInviteByCode>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetInviteByCode<TData = Awaited<ReturnType<typeof getInviteByCode>>, TError = void>(
  inviteId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteByCode>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getInviteByCode>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetInviteByCode<TData = Awaited<ReturnType<typeof getInviteByCode>>, TError = void>(
  inviteId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteByCode>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get invite by invite id
 */

export function useGetInviteByCode<TData = Awaited<ReturnType<typeof getInviteByCode>>, TError = void>(
  inviteId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteByCode>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetInviteByCodeQueryOptions(inviteId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Responds to the invite using the invite code
Only supports accepting the invite with the current invite code approach
 * @summary Activate the invite code
 */
export const responseToInvite = (responseToInviteBody: ResponseToInviteBody, signal?: AbortSignal) => {
  return customAxios<ResponseToInvite200>({
    url: `/user/inviteV2/respond`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: responseToInviteBody,
    signal,
  });
};

export const getResponseToInviteMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof responseToInvite>>,
    TError,
    { data: ResponseToInviteBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof responseToInvite>>,
  TError,
  { data: ResponseToInviteBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof responseToInvite>>, { data: ResponseToInviteBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return responseToInvite(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResponseToInviteMutationResult = NonNullable<Awaited<ReturnType<typeof responseToInvite>>>;
export type ResponseToInviteMutationBody = ResponseToInviteBody;
export type ResponseToInviteMutationError = void;

/**
 * @summary Activate the invite code
 */
export const useResponseToInvite = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof responseToInvite>>,
    TError,
    { data: ResponseToInviteBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof responseToInvite>>,
  TError,
  { data: ResponseToInviteBody },
  TContext
> => {
  const mutationOptions = getResponseToInviteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const forgotPasswordV2 = (forgotPasswordV2Body: ForgotPasswordV2Body, signal?: AbortSignal) => {
  return customAxios<ForgotPasswordChallenged>({
    url: `/v2/login/forgot-password`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: forgotPasswordV2Body,
    signal,
  });
};

export const getForgotPasswordV2MutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forgotPasswordV2>>,
    TError,
    { data: ForgotPasswordV2Body },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof forgotPasswordV2>>,
  TError,
  { data: ForgotPasswordV2Body },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof forgotPasswordV2>>, { data: ForgotPasswordV2Body }> = (
    props
  ) => {
    const { data } = props ?? {};

    return forgotPasswordV2(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ForgotPasswordV2MutationResult = NonNullable<Awaited<ReturnType<typeof forgotPasswordV2>>>;
export type ForgotPasswordV2MutationBody = ForgotPasswordV2Body;
export type ForgotPasswordV2MutationError = unknown;

export const useForgotPasswordV2 = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forgotPasswordV2>>,
    TError,
    { data: ForgotPasswordV2Body },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof forgotPasswordV2>>,
  TError,
  { data: ForgotPasswordV2Body },
  TContext
> => {
  const mutationOptions = getForgotPasswordV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Logs in user for the mobile app.
 * @deprecated
 */
export const loginUser = (loginUserBody: LoginUserBody, signal?: AbortSignal) => {
  return customAxios<LoginUser200>({
    url: `/login`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: loginUserBody,
    signal,
  });
};

export const getLoginUserMutationOptions = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof loginUser>>, TError, { data: LoginUserBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof loginUser>>, TError, { data: LoginUserBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginUser>>, { data: LoginUserBody }> = (props) => {
    const { data } = props ?? {};

    return loginUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginUserMutationResult = NonNullable<Awaited<ReturnType<typeof loginUser>>>;
export type LoginUserMutationBody = LoginUserBody;
export type LoginUserMutationError = ErrorMessage;

/**
 * @deprecated
 */
export const useLoginUser = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof loginUser>>, TError, { data: LoginUserBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof loginUser>>, TError, { data: LoginUserBody }, TContext> => {
  const mutationOptions = getLoginUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const verifyMFA = (verifyMFABody: VerifyMFABody, signal?: AbortSignal) => {
  return customAxios<void>({
    url: `/login/verifyMFA`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: verifyMFABody,
    signal,
  });
};

export const getVerifyMFAMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof verifyMFA>>, TError, { data: VerifyMFABody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof verifyMFA>>, TError, { data: VerifyMFABody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyMFA>>, { data: VerifyMFABody }> = (props) => {
    const { data } = props ?? {};

    return verifyMFA(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type VerifyMFAMutationResult = NonNullable<Awaited<ReturnType<typeof verifyMFA>>>;
export type VerifyMFAMutationBody = VerifyMFABody;
export type VerifyMFAMutationError = unknown;

/**
 * @deprecated
 */
export const useVerifyMFA = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof verifyMFA>>, TError, { data: VerifyMFABody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof verifyMFA>>, TError, { data: VerifyMFABody }, TContext> => {
  const mutationOptions = getVerifyMFAMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const forgotPassword = (forgotPasswordBody: ForgotPasswordBody, signal?: AbortSignal) => {
  return customAxios<CodeDeliveryDetailsType>({
    url: `/login/forgotPassword`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: forgotPasswordBody,
    signal,
  });
};

export const getForgotPasswordMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forgotPassword>>,
    TError,
    { data: ForgotPasswordBody },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof forgotPassword>>, TError, { data: ForgotPasswordBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof forgotPassword>>, { data: ForgotPasswordBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return forgotPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof forgotPassword>>>;
export type ForgotPasswordMutationBody = ForgotPasswordBody;
export type ForgotPasswordMutationError = unknown;

/**
 * @deprecated
 */
export const useForgotPassword = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof forgotPassword>>,
    TError,
    { data: ForgotPasswordBody },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof forgotPassword>>, TError, { data: ForgotPasswordBody }, TContext> => {
  const mutationOptions = getForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const confirmForgotPassword = (confirmForgotPasswordBody: ConfirmForgotPasswordBody, signal?: AbortSignal) => {
  return customAxios<void>({
    url: `/login/confirmForgotPassword`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: confirmForgotPasswordBody,
    signal,
  });
};

export const getConfirmForgotPasswordMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmForgotPassword>>,
    TError,
    { data: ConfirmForgotPasswordBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof confirmForgotPassword>>,
  TError,
  { data: ConfirmForgotPasswordBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof confirmForgotPassword>>,
    { data: ConfirmForgotPasswordBody }
  > = (props) => {
    const { data } = props ?? {};

    return confirmForgotPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof confirmForgotPassword>>>;
export type ConfirmForgotPasswordMutationBody = ConfirmForgotPasswordBody;
export type ConfirmForgotPasswordMutationError = unknown;

/**
 * @deprecated
 */
export const useConfirmForgotPassword = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmForgotPassword>>,
    TError,
    { data: ConfirmForgotPasswordBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof confirmForgotPassword>>,
  TError,
  { data: ConfirmForgotPasswordBody },
  TContext
> => {
  const mutationOptions = getConfirmForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const refreshToken = (refreshTokenBody: RefreshTokenBody, signal?: AbortSignal) => {
  return customAxios<RefreshToken200>({
    url: `/login/refresh`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: refreshTokenBody,
    signal,
  });
};

export const getRefreshTokenMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refreshToken>>, TError, { data: RefreshTokenBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof refreshToken>>, TError, { data: RefreshTokenBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof refreshToken>>, { data: RefreshTokenBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return refreshToken(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RefreshTokenMutationResult = NonNullable<Awaited<ReturnType<typeof refreshToken>>>;
export type RefreshTokenMutationBody = RefreshTokenBody;
export type RefreshTokenMutationError = unknown;

/**
 * @deprecated
 */
export const useRefreshToken = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refreshToken>>, TError, { data: RefreshTokenBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof refreshToken>>, TError, { data: RefreshTokenBody }, TContext> => {
  const mutationOptions = getRefreshTokenMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const revokeWebAppSession = (revokeWebAppSessionBody: RevokeWebAppSessionBody) => {
  return customAxios<void>({
    url: `/login/revokeSession`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: revokeWebAppSessionBody,
  });
};

export const getRevokeWebAppSessionMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeWebAppSession>>,
    TError,
    { data: RevokeWebAppSessionBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof revokeWebAppSession>>,
  TError,
  { data: RevokeWebAppSessionBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof revokeWebAppSession>>,
    { data: RevokeWebAppSessionBody }
  > = (props) => {
    const { data } = props ?? {};

    return revokeWebAppSession(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RevokeWebAppSessionMutationResult = NonNullable<Awaited<ReturnType<typeof revokeWebAppSession>>>;
export type RevokeWebAppSessionMutationBody = RevokeWebAppSessionBody;
export type RevokeWebAppSessionMutationError = unknown;

/**
 * @deprecated
 */
export const useRevokeWebAppSession = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeWebAppSession>>,
    TError,
    { data: RevokeWebAppSessionBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof revokeWebAppSession>>,
  TError,
  { data: RevokeWebAppSessionBody },
  TContext
> => {
  const mutationOptions = getRevokeWebAppSessionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Register consent or acknowledgement for a user.
 */
export const createSignupConsents = (createSignupConsentsBody: CreateSignupConsentsBody, signal?: AbortSignal) => {
  return customAxios<CreateSignupConsents200>({
    url: `/signup/acknowledgements-and-consents`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createSignupConsentsBody,
    signal,
  });
};

export const getCreateSignupConsentsMutationOptions = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSignupConsents>>,
    TError,
    { data: CreateSignupConsentsBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createSignupConsents>>,
  TError,
  { data: CreateSignupConsentsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createSignupConsents>>,
    { data: CreateSignupConsentsBody }
  > = (props) => {
    const { data } = props ?? {};

    return createSignupConsents(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSignupConsentsMutationResult = NonNullable<Awaited<ReturnType<typeof createSignupConsents>>>;
export type CreateSignupConsentsMutationBody = CreateSignupConsentsBody;
export type CreateSignupConsentsMutationError = ErrorMessage;

export const useCreateSignupConsents = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSignupConsents>>,
    TError,
    { data: CreateSignupConsentsBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createSignupConsents>>,
  TError,
  { data: CreateSignupConsentsBody },
  TContext
> => {
  const mutationOptions = getCreateSignupConsentsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const verifyEmail = (verifyEmailBody: VerifyEmailBody, signal?: AbortSignal) => {
  return customAxios<VerificationResponse>({
    url: `/signup/verifyEmail`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: verifyEmailBody,
    signal,
  });
};

export const getVerifyEmailMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof verifyEmail>>, TError, { data: VerifyEmailBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof verifyEmail>>, TError, { data: VerifyEmailBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyEmail>>, { data: VerifyEmailBody }> = (props) => {
    const { data } = props ?? {};

    return verifyEmail(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type VerifyEmailMutationResult = NonNullable<Awaited<ReturnType<typeof verifyEmail>>>;
export type VerifyEmailMutationBody = VerifyEmailBody;
export type VerifyEmailMutationError = unknown;

export const useVerifyEmail = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof verifyEmail>>, TError, { data: VerifyEmailBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof verifyEmail>>, TError, { data: VerifyEmailBody }, TContext> => {
  const mutationOptions = getVerifyEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
export const confirmEmail = (confirmEmailBody: ConfirmEmailBody, signal?: AbortSignal) => {
  return customAxios<ConfirmationCodeResponse>({
    url: `/signup/confirmEmail`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: confirmEmailBody,
    signal,
  });
};

export const getConfirmEmailMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof confirmEmail>>, TError, { data: ConfirmEmailBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof confirmEmail>>, TError, { data: ConfirmEmailBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof confirmEmail>>, { data: ConfirmEmailBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return confirmEmail(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmEmailMutationResult = NonNullable<Awaited<ReturnType<typeof confirmEmail>>>;
export type ConfirmEmailMutationBody = ConfirmEmailBody;
export type ConfirmEmailMutationError = unknown;

export const useConfirmEmail = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof confirmEmail>>, TError, { data: ConfirmEmailBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof confirmEmail>>, TError, { data: ConfirmEmailBody }, TContext> => {
  const mutationOptions = getConfirmEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
export const verifyMobile = (verifyMobileBody: VerifyMobileBody, signal?: AbortSignal) => {
  return customAxios<VerificationResponse>({
    url: `/signup/verifyMobile`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: verifyMobileBody,
    signal,
  });
};

export const getVerifyMobileMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof verifyMobile>>, TError, { data: VerifyMobileBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof verifyMobile>>, TError, { data: VerifyMobileBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyMobile>>, { data: VerifyMobileBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return verifyMobile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type VerifyMobileMutationResult = NonNullable<Awaited<ReturnType<typeof verifyMobile>>>;
export type VerifyMobileMutationBody = VerifyMobileBody;
export type VerifyMobileMutationError = unknown;

export const useVerifyMobile = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof verifyMobile>>, TError, { data: VerifyMobileBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof verifyMobile>>, TError, { data: VerifyMobileBody }, TContext> => {
  const mutationOptions = getVerifyMobileMutationOptions(options);

  return useMutation(mutationOptions);
};
export const confirmMobile = (confirmMobileBody: ConfirmMobileBody, signal?: AbortSignal) => {
  return customAxios<ConfirmationCodeResponse>({
    url: `/signup/confirmMobile`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: confirmMobileBody,
    signal,
  });
};

export const getConfirmMobileMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmMobile>>,
    TError,
    { data: ConfirmMobileBody },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof confirmMobile>>, TError, { data: ConfirmMobileBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof confirmMobile>>, { data: ConfirmMobileBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return confirmMobile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmMobileMutationResult = NonNullable<Awaited<ReturnType<typeof confirmMobile>>>;
export type ConfirmMobileMutationBody = ConfirmMobileBody;
export type ConfirmMobileMutationError = unknown;

export const useConfirmMobile = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmMobile>>,
    TError,
    { data: ConfirmMobileBody },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof confirmMobile>>, TError, { data: ConfirmMobileBody }, TContext> => {
  const mutationOptions = getConfirmMobileMutationOptions(options);

  return useMutation(mutationOptions);
};
export const resendCode = (resendCodeBody: ResendCodeBody, signal?: AbortSignal) => {
  return customAxios<_CodeDeliveryDetails>({
    url: `/signup/resendCode`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: resendCodeBody,
    signal,
  });
};

export const getResendCodeMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof resendCode>>, TError, { data: ResendCodeBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof resendCode>>, TError, { data: ResendCodeBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof resendCode>>, { data: ResendCodeBody }> = (props) => {
    const { data } = props ?? {};

    return resendCode(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResendCodeMutationResult = NonNullable<Awaited<ReturnType<typeof resendCode>>>;
export type ResendCodeMutationBody = ResendCodeBody;
export type ResendCodeMutationError = unknown;

export const useResendCode = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof resendCode>>, TError, { data: ResendCodeBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof resendCode>>, TError, { data: ResendCodeBody }, TContext> => {
  const mutationOptions = getResendCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Register User
 */
export const registerUser = (registerUserBody: RegisterUserBody, signal?: AbortSignal) => {
  return customAxios<RegisterUser200>({
    url: `/signup/register`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: registerUserBody,
    signal,
  });
};

export const getRegisterUserMutationOptions = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof registerUser>>, TError, { data: RegisterUserBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof registerUser>>, TError, { data: RegisterUserBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof registerUser>>, { data: RegisterUserBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return registerUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegisterUserMutationResult = NonNullable<Awaited<ReturnType<typeof registerUser>>>;
export type RegisterUserMutationBody = RegisterUserBody;
export type RegisterUserMutationError = ErrorMessage;

export const useRegisterUser = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof registerUser>>, TError, { data: RegisterUserBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof registerUser>>, TError, { data: RegisterUserBody }, TContext> => {
  const mutationOptions = getRegisterUserMutationOptions(options);

  return useMutation(mutationOptions);
};
