export const getTextAreaBaseVariantTokens = (size: '$sm' | '$md' | '$lg' | '$xl') => {
  switch (size) {
    case '$sm':
      return {
        paddingHorizontal: '$space.input/space/horizontal-padding-sm',
        paddingVertical: '$space.input/space/vertical-padding-sm',
        height: '$size.input/size/sm',
        fontSize: '$sm',
        fontWeight: '$sm',
        lineHeight: '$sm',
        letterSpacing: '$sm',
        minHeight: 80,
      } as const;
    case '$md':
      return {
        paddingHorizontal: '$space.input/space/horizontal-padding-md',
        paddingVertical: '$space.input/space/vertical-padding-md',
        height: '$size.input/size/md',
        fontSize: '$sm',
        fontWeight: '$sm',
        lineHeight: '$sm',
        letterSpacing: '$sm',
        minHeight: 100,
      } as const;
    case '$lg':
      return {
        paddingHorizontal: '$space.input/space/horizontal-padding-lg',
        paddingVertical: '$space.input/space/vertical-padding-lg',
        height: '$size.input/size/lg',
        fontSize: '$md',
        fontWeight: '$md',
        lineHeight: '$md',
        letterSpacing: '$md',
        minHeight: 120,
      } as const;
    case '$xl':
      return {
        paddingHorizontal: '$space.input/space/horizontal-padding-xl',
        paddingVertical: '$space.input/space/vertical-padding-xl',
        height: '$size.input/size/xl',
        fontSize: '$md',
        fontWeight: '$md',
        lineHeight: '$md',
        letterSpacing: '$md',
        minHeight: 140,
      } as const;
  }
};

export interface TextAreaFrameExtraProps {
  error?: string;
}

export type TextAreaStatus = 'default' | 'success' | 'error';
