import type { ToggleProps } from '../types';

/**
 * Universal icon used in the toggle component
 */
export const ToggleIcon = ({
  icon: IconComponent,
  active,
  disabled,
}: Required<Pick<ToggleProps, 'icon'>> & { active: boolean; disabled: boolean }) => {
  return (
    <IconComponent
      size={24}
      color={(() => {
        if (disabled) {
          return active ? '$toggle/color/toggle-bg-disabled' : '$toggle/color/toggle-fg-disabled';
        }
        return active ? '$toggle/color/toggle-fg-selected' : '$toggle/color/toggle-fg-default';
      })()}
      // @ts-expect-error -- We can use flexShrink here
      flexShrink={0} // Keeps icon the same size
    />
  );
};
