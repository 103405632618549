import { styled, Text, useMedia } from 'tamagui';
import type { TextProps } from 'tamagui';

export const Heading = styled(Text, {
  tag: 'span',
  name: 'Heading',
  accessibilityRole: 'header',
  fontFamily: '$heading',
  margin: 0,
});

export default Heading;

const H1XlMd = styled(Heading, {
  name: 'H1',
  tag: 'h1',
  fontSize: '$3xl',
  lineHeight: '$3xl',
  fontWeight: '$3xl',
});

const H1SmXss = styled(Heading, {
  name: 'H1',
  tag: 'h1',
  fontSize: '$xl',
  lineHeight: '$xl',
  fontWeight: '$xl',
});

const H2XlMd = styled(Heading, {
  name: 'H2',
  tag: 'h2',
  fontSize: '$2xl',
  lineHeight: '$2xl',
  fontWeight: '$2xl',
});

const H2SmXss = styled(Heading, {
  name: 'H2',
  tag: 'h2',
  fontSize: '$lg-xl',
  lineHeight: '$lg-xl',
  fontWeight: '$lg-xl',
});

const H3XlMd = styled(Heading, {
  name: 'H3',
  tag: 'h3',
  fontSize: '$xl',
  lineHeight: '$xl',
  fontWeight: '$xl',
});

const H3SmXss = styled(Heading, {
  name: 'H3',
  tag: 'h3',
  fontSize: '$lg',
  lineHeight: '$lg',
  fontWeight: '$lg',
});

const H4XlMd = styled(Heading, {
  name: 'H4',
  tag: 'h4',
  fontSize: '$lg',
  lineHeight: '$lg',
  fontWeight: '$lg',
});

const H4SmXss = styled(Heading, {
  name: 'H4',
  tag: 'h4',
  fontSize: '$md-lg',
  lineHeight: '$md-lg',
  fontWeight: '$md-lg',
});

export const H1 = (props: TextProps) => {
  const media = useMedia();
  return media.tablet || media.laptop || media.desktop ? <H1XlMd {...props} /> : <H1SmXss {...props} />;
};

export const H2 = (props: TextProps) => {
  const media = useMedia();
  return media.tablet || media.laptop || media.desktop ? <H2XlMd {...props} /> : <H2SmXss {...props} />;
};

export const H3 = (props: TextProps) => {
  const media = useMedia();
  return media.tablet || media.laptop || media.desktop ? <H3XlMd {...props} /> : <H3SmXss {...props} />;
};

export const H4 = (props: TextProps) => {
  const media = useMedia();
  return media.tablet || media.laptop || media.desktop ? <H4XlMd {...props} /> : <H4SmXss {...props} />;
};

export const H5 = styled(Heading, {
  name: 'H5',
  tag: 'h5',
  fontSize: '$md',
  lineHeight: '$md',
  fontWeight: '$md',
});
