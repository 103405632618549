'use client';

import { Stack, useMedia } from 'tamagui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Alert } from '../Alert';
import type { FileUploadProps, InputDocument, Document } from './types';
import { MobileFileUpload } from './WebFileUpload/MobileFileUpload';
import { WebFileUpload } from './WebFileUpload/WebFileUpload';
import { DisplayUploadedDoc } from './DisplayUploadedDoc';
import { FILE_SIZE_LIMIT } from './types';
import { resolveArrayOfDictPromises } from './utils';

export const FileUpload = ({
  testId = 'file-upload',
  files,
  category,
  fileTypes,
  errorMessage,
  onDeleteDoc,
  onAddDoc,
  onInvalidFileUploaded,
  disabled,
  multiple = true,
  showFileUploadOnly = false,
}: FileUploadProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const media = useMedia();

  const { t } = useTranslation();

  //Prepare docs for upload
  const onFormatDocuments = async (newFiles: FileList | null) => {
    if (newFiles !== null) {
      //Check file size is valid
      if (Array.from(newFiles).some((file) => file.size > FILE_SIZE_LIMIT)) {
        onInvalidFileUploaded(category, t('lending.origination.conditionalOffer.documentUpload.AVfail_MALWARE'));
        return;
      }

      const blobFiles = Array.from(newFiles).map((file: File) => {
        return {
          fileName: file.name,
          category,
          data: file.arrayBuffer(),
          size: file.size,
          type: file.type,
        };
      });

      //Resolve array buffers
      const arrayBufferFiles = (await resolveArrayOfDictPromises(blobFiles)) as unknown as InputDocument[];

      //Convert to unit 8 array
      const formattedUnit8Files: Document[] = arrayBufferFiles.map((file: InputDocument) => {
        file.data = new Uint8Array(file.data);
        return file as Document;
      });
      setIsLoading(true);
      await onAddDoc(formattedUnit8Files);
      setIsLoading(false);
    } else {
      onInvalidFileUploaded(category, t('lending.origination.conditionalOffer.documentUpload.AVfail_MALWARE'));
    }
  };

  return (
    <Stack gap="$space.lg">
      {files.length > 0 && !showFileUploadOnly ? (
        <DisplayUploadedDoc category={category} files={files} onDeleteDoc={onDeleteDoc} disabled={disabled} />
      ) : null}
      {errorMessage && !showFileUploadOnly ? (
        <Alert severity="danger" variant="inline-transparent">
          {errorMessage}
        </Alert>
      ) : null}
      {media.laptop || media.desktop ? (
        <WebFileUpload
          testId={`${testId}-web-upload`}
          fileTypes={fileTypes}
          processingFile={isLoading}
          onChangeUpload={onFormatDocuments}
          error={errorMessage !== undefined}
          disabled={disabled}
          multiple={multiple}
        />
      ) : (
        <MobileFileUpload
          testId={`${testId}-mobile-upload`}
          fileTypes={fileTypes}
          processingFile={isLoading}
          onChangeUpload={onFormatDocuments}
          disabled={disabled}
          multiple={multiple}
        />
      )}
    </Stack>
  );
};
