'use client';

import { XStack, YStack } from 'tamagui';
import { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from '@cxnpl/ui/icons';
import type { TableBaseProps } from '../TableBase';
import { TableBase } from '../TableBase';
import { Text } from '../../Text';
import { AnimatePresence } from '../../AnimatePresence';

export type TableData = string | JSX.Element;

interface Header {
  data: [TableData] | [TableData, TableData];
  onClickEdit?: () => void;
  disableEditButton?: boolean;
}

export type { Header as InformationTableHeader };

export interface Row {
  data: [TableData] | [TableData, TableData];
  testID?: string;
  onClickEdit?: () => void;
  onClickRow?: () => void;
  backgroundColor?: 'transparent' | 'standard' | 'primary-hover' | 'highlight';
  borderTop?: 'default' | 'divider';
  disableEditButton?: boolean;
  disableFormatting?: boolean;
  removeGap?: boolean;
}

export interface InformationTableProps extends TableBaseProps {
  header?: Header;
  /**
   * Use if you need to have a custom header
   */
  renderHeader?: JSX.Element;
  rows: Row[];
  testID?: string;
  column1FlexBasis?: number;
  /**
   * Manual intervention of column 1 width vs columns 2
   */
  background?: 'transparent' | 'standard';
  /**
   * When provided, renders a 'Show more' button after this row number (starting from 1). Rows after this number will be hidden.
   *
   * Must be less than the number of rows, otherwise it will be ignored.
   */
  showRowCount?: number;
  showMoreText?: string;
  showLessText?: string;
  /**
   * Disable animation of table - required for nested animations
   */
  disableAnimation?: boolean;
}

export function InformationTable({
  header,
  renderHeader,
  rows,
  testID,
  column1FlexBasis = 50,
  background = 'standard',
  showRowCount,
  showMoreText,
  showLessText,
  disableAnimation,
  ...tableBaseProps
}: InformationTableProps): JSX.Element {
  const [isShowMoreOpen, setIsShowMoreOpen] = useState<boolean>();
  const [calculatedShowRowCount, setCalculatedShowRowCount] = useState<number | undefined>(showRowCount);

  const rowIsEditable = (row: Row) => {
    return 'onClickEdit' in row;
  };

  // Remove show more behavior if the user fails to use the interface
  // correctly
  if ((showRowCount && showRowCount > rows.length) || showRowCount === 0) {
    // eslint-disable-next-line no-param-reassign -- enforce override
    setCalculatedShowRowCount(undefined);
  }

  // update the row count only once the drop down for show more
  // Is initially clicked. Prevents undefined states in rendered.
  useEffect(() => {
    if (isShowMoreOpen !== undefined) {
      if (isShowMoreOpen) {
        setCalculatedShowRowCount(rows.length);
      } else {
        setCalculatedShowRowCount(showRowCount);
      }
    }
  }, [isShowMoreOpen, rows.length, showRowCount]);

  const rowsToDisplay = calculatedShowRowCount ? rows.slice(0, calculatedShowRowCount) : rows;

  return (
    <TableBase background={background} testID={testID} {...tableBaseProps}>
      {/* HEADER */}
      {header || renderHeader ? (
        <TableBase.Head testID={`${testID ? `${testID}-` : ''}header`}>
          {renderHeader && !header ? (
            <TableBase.Row>
              <TableBase.Header>{renderHeader}</TableBase.Header>
            </TableBase.Row>
          ) : null}
          {header ? (
            <TableBase.Row>
              {header.data.length === 1 ? (
                <TableBase.Header>{renderComponentOrString(header.data[0], TableBase.HeaderText)}</TableBase.Header>
              ) : (
                <>
                  <TableBase.Header minWidth="20%" flexBasis={`${column1FlexBasis}%`}>
                    {renderComponentOrString(header.data[0], (props) => (
                      <TableBase.HeaderText textAlign="left">{props.children}</TableBase.HeaderText>
                    ))}
                  </TableBase.Header>
                  <TableBase.Header
                    justifyContent="flex-end"
                    minWidth="20%"
                    flexBasis={`${100 - column1FlexBasis - Number(`${header.onClickEdit ? 20 : 0}`)}%`}
                  >
                    {renderComponentOrString(header.data[1], (props) => (
                      <TableBase.HeaderText textAlign="right">{props.children}</TableBase.HeaderText>
                    ))}
                  </TableBase.Header>
                </>
              )}
              {header.onClickEdit ? (
                <TableBase.Header flexBasis={1} justifyContent="flex-end" minWidth="$size.xs">
                  <TableBase.EditButton
                    onPress={header.onClickEdit}
                    testID={`${testID ? `${testID}-` : ''}header-edit`}
                    disabled={!!header.disableEditButton}
                  />
                </TableBase.Header>
              ) : null}
            </TableBase.Row>
          ) : null}
        </TableBase.Head>
      ) : null}

      <TableBase.Body>
        <AnimatePresence>
          {rowsToDisplay.map((row, index) => {
            const borderBottomWidth = !isLastIndex(index, rowsToDisplay, showRowCount) ? 1 : 0;

            return (
              <YStack
                animation={disableAnimation ? undefined : 'quick'}
                key={`${index}-row-key`}
                enterStyle={
                  disableAnimation
                    ? undefined
                    : {
                        opacity: 0,
                        y: 10,
                        scale: 1,
                      }
                }
              >
                {row.disableFormatting ? (
                  renderComponentOrString(row.data[0], TableBase.KeyText)
                ) : (
                  <TableBase.RowHighlighted
                    testID={row.testID ? `${row.testID}-row` : `${testID ? `${testID}-` : ''}row-${index}`}
                    key={`${index}-row-key-highlighted`}
                    borderBottomWidth={borderBottomWidth}
                    hoverStyle={{
                      background: row.onClickRow ? 'primary-hover' : row.backgroundColor,
                    }}
                    cursor={row.onClickRow ? 'pointer' : 'default'}
                    onPress={row.onClickRow}
                    background={row.backgroundColor}
                    borderTop={row.borderTop}
                    width="100%"
                    flexDirection="column"
                    $tablet={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <TableBase.Data
                      key={`${index}-row-key-highlighted-data-col-1`}
                      $tablet={{
                        minWidth: '20%',
                        flexBasis: `${column1FlexBasis}%`,
                      }}
                    >
                      {renderComponentOrString(row.data[0], TableBase.KeyText)}
                    </TableBase.Data>
                    {row.data[1] ? (
                      <TableBase.Data
                        key={`${index}-row-key-highlighted-data-col-2`}
                        gap={row.removeGap ? undefined : '$lg'}
                        justifyContent="space-between"
                        $tablet={{
                          minWidth: '20%',
                          flexBasis: `${100 - column1FlexBasis}%`,
                          justifyContent: 'flex-end',
                        }}
                      >
                        {renderComponentOrString(row.data[1], TableBase.ValueText)}
                        {rowIsEditable(row) ? (
                          <TableBase.EditButton
                            onPress={row.onClickEdit}
                            testID={`${testID ? `${testID}-` : ''}row-${index}-edit`}
                            disabled={!!row.disableEditButton}
                          />
                        ) : null}
                      </TableBase.Data>
                    ) : null}
                  </TableBase.RowHighlighted>
                )}
              </YStack>
            );
          })}

          {showRowCount ? (
            <XStack
              key="show-more-chevron"
              gap="$space.sm"
              padding="$space.lg"
              hoverStyle={{ backgroundColor: '$background/primary-subdued' }}
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              width="100%"
              onPress={() => {
                setIsShowMoreOpen(isShowMoreOpen !== true);
              }}
            >
              {isShowMoreOpen ? (
                <>
                  <ChevronUp color="$button/color/button-primary-text" size="$xs" />
                  {showLessText ? (
                    <Text variant="bodyMediumEm" color="$button/color/button-primary-text">
                      {showLessText}
                    </Text>
                  ) : undefined}
                </>
              ) : (
                <>
                  <ChevronDown color="$button/color/button-primary-text" size="$xs" />
                  {showMoreText ? (
                    <Text variant="bodyMediumEm" color="$button/color/button-primary-text">
                      {showMoreText}
                    </Text>
                  ) : undefined}
                </>
              )}
            </XStack>
          ) : undefined}
        </AnimatePresence>
      </TableBase.Body>
    </TableBase>
  );
}

// If limiting the amount of rows through showRowCount then add an additional
// boarder to the last element in the array
function isLastIndex<T>(index: number, array: T[], showRowCount?: number) {
  return showRowCount ? index === array.length : index === array.length - 1;
}
const renderComponentOrString = (value: TableData, TextWrapper: React.FC<{ children: React.ReactNode }>) => {
  return typeof value !== 'string' ? value : <TextWrapper>{value}</TextWrapper>;
};
