import { styled } from 'tamagui';
import { ToggleStyledContext } from '../types';
import { Text } from '../../Text';

/**
 * Universal text used in the toggle component
 */
export const ToggleText = styled(Text, {
  name: 'ToggleText',
  context: ToggleStyledContext,
  userSelect: 'none',
  ellipse: false,
  flexWrap: 'wrap',
  variants: {
    active: {
      true: {
        color: '$toggle/color/toggle-fg-selected',
      },
      false: {
        color: '$toggle/color/toggle-fg-default',
      },
    },
    disabled: {
      true: (_, { props }: { props: { active: boolean } }) => ({
        color: props.active ? '$toggle/color/toggle-bg-disabled' : '$toggle/color/toggle-fg-disabled',
      }),
    },
  } as const,
  defaultVariants: {
    active: false,
  },
});
