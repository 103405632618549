import { Stack, styled } from 'tamagui';
import { ToggleStyledContext } from '../types';

export const BaseToggleFrame = styled(Stack, {
  name: 'ButtonToggleFrame',
  tag: 'button',
  role: 'button',
  context: ToggleStyledContext,
  cursor: 'pointer',
  backgroundColor: '$toggle/color/toggle-bg-default',
  borderColor: '$toggle/color/toggle-border-default',
  borderWidth: 1,
  gap: '$space.sm',
  paddingHorizontal: '$space.lg',
  hoverStyle: {
    backgroundColor: '$toggle/color/toggle-bg-hover',
    borderColor: '$toggle/color/toggle-border-default',
  },
  focusStyle: {
    outlineWidth: 2,
    outlineColor: '$button/color/button-focus-border',
    outlineStyle: 'solid',
  },
  variants: {
    active: {
      true: {
        zIndex: 1,
        backgroundColor: '$toggle/color/toggle-bg-selected', // Use theme values
        borderColor: '$toggle/color/toggle-border-selected', // Use theme values
        hoverStyle: {
          backgroundColor: '$toggle/color/toggle-bg-selected', // Use theme values
          borderColor: '$toggle/color/toggle-border-selected', // Use theme values
        },
      },
      false: {},
    },
    disabled: {
      true: (_, { props }: { props: { active: boolean } }) => ({
        color: '$toggle/color/toggle-fg-disabled',
        borderColor: props.active ? '$toggle/color/toggle-fg-disabled' : '$border/disabled',
        backgroundColor: props.active ? '$toggle/color/toggle-fg-disabled' : '$toggle/color/toggle-bg-disabled',
        cursor: 'auto',
        hoverStyle: {
          borderColor: props.active ? '$toggle/color/toggle-fg-disabled' : '$border/disabled',
          backgroundColor: props.active ? '$toggle/color/toggle-fg-disabled' : '$toggle/color/toggle-bg-disabled',
        },
        focusStyle: {
          outlineWidth: 0,
        },
      }),
    },
    size: {
      md: {
        paddingVertical: '$space.md',
      },
      lg: {
        paddingVertical: '$space.lg',
      },
    },
  },
});
