// THIS IS AN AUTOGENERATED FILE. REGENERATE USING THE TOKENS2CODE FIGMA PLUGIN

import { linkVariablesToTokens } from '../variables';

const coreTokensColorRaw = {
  'accent/1': '#fffeee',
  'accent/2': '#fffdca',
  'accent/3': '#fff854',
  'accent/4': '#faec20',
  'accent/5': '#bfaa3f',
  'accent/6': '#594f1d',
  'accent/7': '#ff00d0',
  'accent/8': '#ff00d0',
  'accent/9': '#ff00d0',
  'accent/10': '#ff00d0',
  'accent/11': '#ff00d0',
  'accent/12': '#ff00d0',
  'black/alpha-0': 'rgba(0, 0, 0, 0.0000)',
  'black/alpha-50': 'rgba(0, 0, 0, 0.5000)',
  'black/alpha-60': 'rgba(0, 0, 0, 0.6000)',
  'black/alpha-100': '#000000',
  'danger/1': '#ffe6e0',
  'danger/2': '#ffc9c2',
  'danger/3': '#ff6966',
  'danger/4': '#ff221e',
  'danger/5': '#cc0000',
  'danger/6': '#8f0300',
  'danger/7': '#570200',
  'danger/8': '#ff00d0',
  'danger/9': '#ff00d0',
  'danger/10': '#ff00d0',
  'danger/11': '#ff00d0',
  'danger/12': '#ff00d0',
  'info/1': '#e5f9ff',
  'info/2': '#c7f1ff',
  'info/3': '#1fc7ff',
  'info/4': '#007db8',
  'info/5': '#005b94',
  'info/6': '#003e70',
  'info/7': '#ff00d0',
  'info/8': '#ff00d0',
  'info/9': '#ff00d0',
  'info/10': '#ff00d0',
  'info/11': '#ff00d0',
  'info/12': '#ff00d0',
  'neutral/1': '#f6f6f6',
  'neutral/2': '#e5e5e5',
  'neutral/3': '#cccccc',
  'neutral/4': '#74767b',
  'neutral/5': '#4b4b4f',
  'neutral/6': '#232428',
  'neutral/7': '#070708',
  'neutral/8': '#ff00d0',
  'neutral/9': '#ff00d0',
  'neutral/10': '#ff00d0',
  'neutral/11': '#ff00d0',
  'neutral/12': '#ff00d0',
  'primary/1': '#f2e8fd',
  'primary/2': '#ebdcfd',
  'primary/3': '#d6b6fa',
  'primary/4': '#7b14ef',
  'primary/5': '#5c0fb3',
  'primary/6': '#4a0c8f',
  'primary/7': '#2b0754',
  'primary/8': '#ff00d0',
  'primary/9': '#ff00d0',
  'primary/10': '#ff00d0',
  'primary/11': '#ff00d0',
  'primary/12': '#ff00d0',
  'secondary/1': '#c497fe',
  'secondary/2': '#9d79cb',
  'secondary/3': '#ff00d0',
  'secondary/4': '#ff00d0',
  'secondary/5': '#ff00d0',
  'secondary/6': '#ff00d0',
  'secondary/7': '#ff00d0',
  'secondary/8': '#ff00d0',
  'secondary/9': '#ff00d0',
  'secondary/10': '#ff00d0',
  'secondary/11': '#ff00d0',
  'secondary/12': '#ff00d0',
  'success/1': '#e7fdf1',
  'success/2': '#c5fbdf',
  'success/3': '#00d657',
  'success/4': '#008536',
  'success/5': '#006629',
  'success/6': '#004d1c',
  'success/7': '#ff00d0',
  'success/8': '#ff00d0',
  'success/9': '#ff00d0',
  'success/10': '#ff00d0',
  'success/11': '#ff00d0',
  'success/12': '#ff00d0',
  'tertiary/1': '#ff00d0',
  'tertiary/2': '#ff00d0',
  'tertiary/3': '#ff00d0',
  'tertiary/4': '#ff00d0',
  'tertiary/5': '#ff00d0',
  'tertiary/6': '#ff00d0',
  'tertiary/7': '#ff00d0',
  'tertiary/8': '#ff00d0',
  'tertiary/9': '#ff00d0',
  'tertiary/10': '#ff00d0',
  'tertiary/11': '#ff00d0',
  'tertiary/12': '#ff00d0',
  'warning/1': '#fff8ed',
  'warning/2': '#ffe9c7',
  'warning/3': '#ffb74b',
  'warning/4': '#bf8938',
  'warning/5': '#735222',
  'warning/6': '#f59e0b',
  'warning/7': '#ff00d0',
  'warning/8': '#ff00d0',
  'warning/9': '#ff00d0',
  'warning/10': '#ff00d0',
  'warning/11': '#ff00d0',
  'warning/12': '#ff00d0',
  'white/alpha-0': 'rgba(255, 255, 255, 0.0000)',
  'white/alpha-50': 'rgba(255, 255, 255, 0.5000)',
  'white/alpha-60': 'rgba(255, 255, 255, 0.6000)',
  'white/alpha-100': '#ffffff',
} as const;
export const coreTokensColor = linkVariablesToTokens(coreTokensColorRaw, 'color');

const coreTokensRadiusRaw = {
  '0': 0,
  '2xs': 8,
  '3xs': 6,
  '4xs': 4,
  '5xs': 2,
  FULL: 128,
  NA1: 0,
  NA2: 0,
  NA3: 0,
  NA4: 0,
  lg: 32,
  md: 20,
  sm: 16,
  xs: 12,
} as const;
export const coreTokensRadius = linkVariablesToTokens(coreTokensRadiusRaw, 'radius');

const coreTokensSizeRaw = {
  '0': 0,
  '1': 20,
  '2': 28,
  '3': 36,
  '4': 44,
  '5': 56,
  '6': 64,
  '7': 72,
  '8': 88,
  '9': 96,
  '10': 112,
  '11': 124,
  '12': 144,
  '13': 164,
  '14': 184,
  '15': 204,
  '16': 224,
  '17': 224,
  '18': 244,
  '19': 264,
  '20': 280,
  '0-5': 8,
  '0-12-5': 1,
  '0-25': 2,
  '0-75': 16,
  '0_percent': '0%',
  '1-5': 24,
  '2-5': 32,
  '3-5': 40,
  '4-5': 48,
  '12-5_percent': '12.5%',
  '25_percent': '25%',
  '37-5_percent': '37.5%',
  '50_percent': '50%',
  '62-5_percent': '62.5%',
  '75_percent': '75%',
  '87-5_percent': '87.5%',
  '100_percent': '100%',
} as const;
export const coreTokensSize = linkVariablesToTokens(coreTokensSizeRaw, 'size');

const coreTokensSpaceRaw = {
  '0': 0,
  '1': 2,
  '2': 8,
  '3': 12,
  '4': 18,
  '5': 24,
  '6': 26,
  '7': 28,
  '8': 32,
  '9': 36,
  '10': 40,
  '11': 44,
  '12': 48,
  '14': 56,
  '16': 64,
  '20': 80,
  '24': 96,
  '28': 112,
  '32': 128,
  '36': 144,
  '40': 160,
  '44': 176,
  '48': 192,
  '52': 208,
  '56': 224,
  '60': 240,
  '64': 256,
  '72': 288,
  '80': 384,
  '0-5': 1,
  '0-25': 0.5,
  '0-75': 1.5,
  '1-5': 4,
  '2-5': 10,
  '3-5': 16,
  '4-5': 20,
} as const;
export const coreTokensSpace = linkVariablesToTokens(coreTokensSpaceRaw, 'space');
