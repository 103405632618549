import { getTokens } from 'tamagui';
import { Platform } from 'react-native';
import type { InputBaseProps } from './Input';

const tokens = getTokens();
// TODO: Investigate and resolve the root cause of why fonts look bad on specific iOS devices. https://constantinople.atlassian.net/browse/PWA-1288
const ADJUSTMENT_CONSTANT = -2; // iOS issue: Temporary adjustment to fix inconsistent font rendering on certain ios devices.

/**
 * Calculates the vertical padding for the input label based on the input size.
 */
export const inputLabelPaddingTop = (size: InputBaseProps['size']): number => {
  switch (size) {
    case '$sm':
      return tokens.space['input/space/vertical-padding-sm'].val;
    case '$md':
      return tokens.space['input/space/vertical-padding-md'].val;
    case '$lg':
      return tokens.space['input/space/vertical-padding-lg'].val;
    case '$xl':
      return tokens.space['input/space/vertical-padding-xl'].val;
    default:
      return 0;
  }
};

/**
 * Calculates the horizontal padding for the input label based on the input size and optionally includes space for an icon.
 */
export const inputLabelPaddingLeft = (size: InputBaseProps['size'], includeIconSize?: boolean): number => {
  let paddingLeft = 0;
  switch (size) {
    case '$sm':
      paddingLeft += tokens.space['input/space/horizontal-padding-sm'].val;
      break;
    case '$md':
      paddingLeft += tokens.space['input/space/horizontal-padding-md'].val;
      break;
    case '$lg':
      paddingLeft += tokens.space['input/space/horizontal-padding-lg'].val;
      break;
    case '$xl':
      paddingLeft += tokens.space['input/space/horizontal-padding-xl'].val;
      break;
    default:
      break;
  }

  if (includeIconSize) {
    paddingLeft += iconSize(size) + tokens.space['$input/space/icon-gap'].val;
  }
  return paddingLeft;
};

/**
 * Calculates the top padding for the floating label, with an option to include the border width in the calculation.
 */

export const floatingLabelPaddingTop = (size: InputBaseProps['size'], includeBorder = true): number => {
  let paddingTop = 0;
  switch (size) {
    case '$sm':
      paddingTop += tokens.space['input/space/vertical-padding-with-label-sm'].val;
      break;
    case '$md':
      paddingTop += tokens.space['input/space/vertical-padding-with-label-md'].val;
      break;
    case '$lg':
      paddingTop =
        -tokens.space['input/space/vertical-padding-sm'].val +
        tokens.space['input/space/vertical-padding-with-label-lg'].val;
      break;
    case '$xl':
      paddingTop += tokens.space['input/space/vertical-padding-with-label-xl'].val;
      break;
    default:
      break;
  }

  if (includeBorder) {
    paddingTop += tokens.size['input/size/border-width'].val;
  }
  return paddingTop;
};

/*
 * Calculates the bottom padding for the floating label, with an option to include the border width in the calculation.
 */
export const floatingLabelPaddingBottom = (size: InputBaseProps['size'], includeBorder = true): number => {
  let paddingBottom = 0;

  switch (size) {
    case '$sm':
      paddingBottom += tokens.space['input/space/vertical-padding-with-label-sm'].val;
      break;

    case '$md':
      paddingBottom += tokens.space['input/space/vertical-padding-with-label-md'].val;
      break;

    case '$lg':
      if (Platform.OS === 'ios') {
        paddingBottom += ADJUSTMENT_CONSTANT + tokens.space['input/space/vertical-padding-with-label-lg'].val;
      } else {
        paddingBottom +=
          -tokens.space['input/space/vertical-padding-sm'].val +
          tokens.space['input/space/vertical-padding-with-label-lg'].val;
      }
      break;

    case '$xl':
      paddingBottom += tokens.space['input/space/vertical-padding-with-label-xl'].val;
      break;

    default:
      break;
  }

  if (includeBorder) {
    paddingBottom += tokens.size['input/size/border-width'].val;
  }

  return paddingBottom;
};

/**
 * Calculates the left padding for the floating label, including space for the border
 * and optionally including space for an icon.
 */
export const floatingLabelPaddingLeft = (size: InputBaseProps['size'], includeIconSize?: boolean): number => {
  return inputLabelPaddingLeft(size, includeIconSize) + tokens.size['input/size/border-width'].val;
};

/**
 * Determines the icon size based on the input size.
 */
export const iconSize = (inputSize: InputBaseProps['size']): number => {
  switch (inputSize) {
    case '$sm':
    case '$md':
      return tokens.size['icon/size/xs'].val;
    case '$lg':
    case '$xl':
      return tokens.size['icon/size/sm'].val;
    default:
      return 0;
  }
};

/**
 * Calculates the top position for the prefix element in an input field.
 * This function adjusts the position specifically for native platforms.
 *
 * Why this is needed:
 * - On native platforms, the prefix top position doesn't automatically adjust
 *   when a floating label is used. This function ensures the layout is consistent.
 *
 */
export const calculatePrefixTop = (renderFloatingLabel: boolean, labelHeight: number, size: InputBaseProps['size']) => {
  if (renderFloatingLabel) {
    if (Platform.OS === 'ios') {
      return tokens.size['input/size/sm'].val;
    }
    return labelHeight + floatingLabelPaddingTop(size, size !== '$md');
  }
  return 0;
};
