'use client';
import { axiosInstance } from '@cxnpl/api/axios';
import { useEffect, useMemo, type ReactNode } from 'react';
import { Provider, type ProviderProps } from 'react-redux';
import 'react-native-get-random-values'; // Required for uuidv4
import { v4 as uuid } from 'uuid';
import type { AppStore } from 'app/store';
import { setupStore } from 'app/store';
import { setAuth0Config, setCustomerNumber, setDeviceId } from 'app/features/auth/authSlice';
import configureAxios from '../../services/axiosConfiguration/configureAxios';
import { storage } from './deviceStorage';
import { sendToLogin } from './sendToLogin';

export const defaultStore = setupStore();

export interface StoreProviderProps extends Partial<ProviderProps> {
  children: ReactNode;

  // TODO: Clean up - add initialState instead of initialStore
  auth0Config?: {
    domain: string;
    clientId: string;
  };
}

export function StoreProvider({ store: initialStore, children, auth0Config }: StoreProviderProps) {
  const store = useMemo(() => (initialStore as AppStore | undefined) ?? defaultStore, [initialStore]);

  // Initializes store with asynchronous data
  useEffect(() => {
    const initStore = async () => {
      // Set Auth0 config
      if (auth0Config) {
        store.dispatch(setAuth0Config(auth0Config));
      }

      // Get Device id
      let deviceId = await storage.deviceId.get();
      if (!deviceId) {
        deviceId = await storage.deviceId.set(uuid());
      }
      store.dispatch(setDeviceId(deviceId));

      // Get Customer number
      const customerNumber = await storage.customerNumber.get();
      if (customerNumber) {
        store.dispatch(setCustomerNumber(customerNumber));
      }
    };

    void initStore();
  }, [auth0Config, store]);

  useEffect(() => {
    const { requestInterceptorId, responseInterceptorId } = configureAxios(axiosInstance, store, sendToLogin);

    return () => {
      axiosInstance.interceptors.response.eject(requestInterceptorId);
      axiosInstance.interceptors.response.eject(responseInterceptorId);
    };
  }, [store]);

  return <Provider store={store}>{children}</Provider>;
}
