/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from '@tanstack/react-query';
import type { MutationFunction, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import type { ErrorMessage, MFAScope, StartMfa200, VerifyMfa200, VerifyMfaBody } from '../api.schemas';
import { customAxios } from '../../axios';

/**
 * Used for step up authentication to initiate the flow
You get back a challengeId that is then passed alongside the received code to the endpoint requiring MFA
Currently SMS is the only channel supported
 * @summary Start an MFA request
 */
export const startMfa = (scope: MFAScope, signal?: AbortSignal) => {
  return customAxios<StartMfa200>({ url: `/mfa/start/${scope}`, method: 'POST', signal });
};

export const getStartMfaMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof startMfa>>, TError, { scope: MFAScope }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof startMfa>>, TError, { scope: MFAScope }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof startMfa>>, { scope: MFAScope }> = (props) => {
    const { scope } = props ?? {};

    return startMfa(scope);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartMfaMutationResult = NonNullable<Awaited<ReturnType<typeof startMfa>>>;

export type StartMfaMutationError = unknown;

/**
 * @summary Start an MFA request
 */
export const useStartMfa = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof startMfa>>, TError, { scope: MFAScope }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof startMfa>>, TError, { scope: MFAScope }, TContext> => {
  const mutationOptions = getStartMfaMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Verify the mfa challenge and allow to get an elevated access token if successful
 * @summary Verify mfa
 */
export const verifyMfa = (verifyMfaBody: VerifyMfaBody, signal?: AbortSignal) => {
  return customAxios<VerifyMfa200>({
    url: `/mfa/mfa/verify`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: verifyMfaBody,
    signal,
  });
};

export const getVerifyMfaMutationOptions = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof verifyMfa>>, TError, { data: VerifyMfaBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof verifyMfa>>, TError, { data: VerifyMfaBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyMfa>>, { data: VerifyMfaBody }> = (props) => {
    const { data } = props ?? {};

    return verifyMfa(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type VerifyMfaMutationResult = NonNullable<Awaited<ReturnType<typeof verifyMfa>>>;
export type VerifyMfaMutationBody = VerifyMfaBody;
export type VerifyMfaMutationError = ErrorMessage;

/**
 * @summary Verify mfa
 */
export const useVerifyMfa = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof verifyMfa>>, TError, { data: VerifyMfaBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof verifyMfa>>, TError, { data: VerifyMfaBody }, TContext> => {
  const mutationOptions = getVerifyMfaMutationOptions(options);

  return useMutation(mutationOptions);
};
