import { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'app/store';
import { tokenInfoNoInterceptor } from 'app/api/no-interceptor/tokenInfo';
import { resetTokenInfo, setTokenInfo } from 'app/features/auth/authSlice';

/** Hook to initialize token info using tokens from cookies */
export const useInitializeTokenInfo = () => {
  const dispatch = useAppDispatch();

  const fetchTokenInfo = useCallback(async () => {
    try {
      const response = await tokenInfoNoInterceptor();
      if (response) {
        dispatch(setTokenInfo(response));
      } else {
        dispatch(resetTokenInfo());
      }
    } catch {
      dispatch(resetTokenInfo());
    }
  }, [dispatch]);

  // Set the token info on mount
  useEffect(() => {
    void fetchTokenInfo();
  }, [fetchTokenInfo]);
};
