import type { FillInFont, GenericFont, GenericTamaguiConfig } from '@tamagui/core';
import { appClient } from '../utils/appClient';
import {
  bodyEmphasisedFont as gsbBodyEmphasisedFont,
  bodyFont as gsbBodyFont,
  headingFont as gsbHeadingFont,
  linkEmphasisedFont as gsbLinkEmphasisedFont,
  linkFont as gsbLinkFont,
} from './gsb';
import {
  bodyEmphasisedFont as cxnplBodyEmphasisedFont,
  bodyFont as cxnplBodyFont,
  headingFont as cxnplHeadingFont,
  linkEmphasisedFont as cxnplLinkEmphasisedFont,
  linkFont as cxnplLinkFont,
} from './cxnpl';
import {
  bodyEmphasisedFont as myobBodyEmphasisedFont,
  bodyFont as myobBodyFont,
  headingFont as myobHeadingFont,
  linkEmphasisedFont as myobLinkEmphasisedFont,
  linkFont as myobLinkFont,
} from './myob';

type GenericTamaguiConfigFonts = GenericTamaguiConfig['fonts'];

interface FontSet extends GenericTamaguiConfigFonts {
  body: FillInFont<
    GenericFont,
    | 'md'
    | 'true'
    | 'xs'
    | 'sm'
    | 'md-lg'
    | 'lg'
    | 'lg-xl'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | '8xl'
  >;
  bodyEmphasised: FillInFont<
    GenericFont,
    | 'md'
    | 'true'
    | 'xs'
    | 'sm'
    | 'md-lg'
    | 'lg'
    | 'lg-xl'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | '8xl'
  >;
  heading: FillInFont<
    GenericFont,
    | 'md'
    | 'true'
    | 'xs'
    | 'sm'
    | 'md-lg'
    | 'lg'
    | 'lg-xl'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | '8xl'
  >;
  link: FillInFont<
    GenericFont,
    | 'md'
    | 'true'
    | 'xs'
    | 'sm'
    | 'md-lg'
    | 'lg'
    | 'lg-xl'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | '8xl'
  >;
  linkEmphasised: FillInFont<
    GenericFont,
    | 'md'
    | 'true'
    | 'xs'
    | 'sm'
    | 'md-lg'
    | 'lg'
    | 'lg-xl'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | '8xl'
  >;
}

// TODO - PWA-1356
const brandName = appClient === 'WEB' ? process.env.BRAND_NAME : process.env.EXPO_PUBLIC_BRAND_NAME;

const fonts: FontSet = {
  body: cxnplBodyFont,
  bodyEmphasised: cxnplBodyEmphasisedFont,
  heading: cxnplHeadingFont,
  link: cxnplLinkFont,
  linkEmphasised: cxnplLinkEmphasisedFont,
} as const;

// Use if/else instead of the statement switch to tree-shake the unused code branches
if (brandName === 'gsb') {
  const gsbFonts: FontSet = {
    body: gsbBodyFont,
    bodyEmphasised: gsbBodyEmphasisedFont,
    heading: gsbHeadingFont,
    link: gsbLinkFont,
    linkEmphasised: gsbLinkEmphasisedFont,
  } as const;
  Object.assign(fonts, gsbFonts);
} else if (brandName === 'myob') {
  const myobFonts: FontSet = {
    body: myobBodyFont,
    bodyEmphasised: myobBodyEmphasisedFont,
    heading: myobHeadingFont,
    link: myobLinkFont,
    linkEmphasised: myobLinkEmphasisedFont,
  } as const;
  Object.assign(fonts, myobFonts);
} else {
  const cxnplFonts: FontSet = {
    body: cxnplBodyFont,
    bodyEmphasised: cxnplBodyEmphasisedFont,
    heading: cxnplHeadingFont,
    link: cxnplLinkFont,
    linkEmphasised: cxnplLinkEmphasisedFont,
  } as const;
  Object.assign(fonts, cxnplFonts);
}

const { body, bodyEmphasised, heading, link, linkEmphasised } = fonts;

export { body, bodyEmphasised, heading, link, linkEmphasised };
