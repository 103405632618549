import { Path, Svg } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={88} height={87} fill="none" {...props}>
    <Path fill="#A7D4CF" d="M73.69 5.72H61.556v14.73H73.69V5.72Z" />
    <Path fill="#DAFFFB" d="M80.665 24.666H7.332v55h73.333v-55Z" />
    <Path
      fill="#CFE5E3"
      d="M86.165 30.166H1.832v-1.833c0-3.212 2.834-4.583 6.35-4.583L44.007 3.583 79.83 23.75c3.501 0 6.335 3.19 6.335 6.416Z"
    />
    <Path
      fill="#fff"
      d="M43.88 24.227c1.591 0 2.88-2.364 2.88-5.28 0-2.917-1.289-5.281-2.88-5.281-1.59 0-2.88 2.364-2.88 5.28 0 2.917 1.29 5.281 2.88 5.281Z"
    />
    <Path
      fill="#00CCB7"
      d="M38.118 52.86v26.245H16.816V52.86a3.195 3.195 0 0 1 3.197-3.196h14.895a3.206 3.206 0 0 1 3.21 3.197Z"
    />
    <Path
      fill="#fff"
      d="M34.908 49.664h-1.346v8.813c0 9.253-7.506 16.746-16.746 16.746v3.882h21.289V52.86c.013-1.76-1.424-3.196-3.197-3.196Z"
      opacity={0.1}
    />
    <Path
      fill="#A7D4CF"
      d="M69.732 51.075V66.76a2.055 2.055 0 0 1-2.057 2.058H50.308a2.055 2.055 0 0 1-2.058-2.058V51.075c0-1.139.919-2.057 2.058-2.057h17.367c1.139 0 2.057.918 2.057 2.057Z"
    />
    <Path
      fill="#fff"
      d="M69.732 51.075v15.698a2.055 2.055 0 0 1-2.057 2.058H50.308a2.055 2.055 0 0 1-2.058-2.058v-1.41h1.592c9.033 0 16.344-7.313 16.344-16.345h1.489c1.139 0 2.057.918 2.057 2.057Z"
      opacity={0.1}
    />
    <Path
      fill="#5874FF"
      d="M85.643 30.177c-.37 0-.834-.092-1.205-.277L43.954 5.813 3.469 29.9a2.286 2.286 0 0 1-3.15-.834 2.286 2.286 0 0 1 .834-3.15L42.842 1.18c.741-.463 1.668-.463 2.316 0l41.69 24.736a2.286 2.286 0 0 1 .833 3.15c-.463.74-1.297 1.111-2.038 1.111Z"
    />
    <Path
      fill="#37383D"
      d="M84.37 81.952v5.384H3.667v-5.384a2.833 2.833 0 0 1 2.834-2.834h75.033c1.566-.013 2.834 1.269 2.834 2.834Z"
    />
    <Path
      fill="#fff"
      d="M81.535 79.106h-1.152a4.65 4.65 0 0 1-4.646 4.645H3.668v3.585h80.701v-5.383a2.844 2.844 0 0 0-2.834-2.847Z"
      opacity={0.1}
    />
    <Path fill="#84FFF1" d="M75.358 2.292H59.887V5.72h15.471V2.292Z" />
  </Svg>
);

Icon.displayName = 'RefinanceHomeLoan';

export const RefinanceHomeLoan = memo<IconProps>(themed(Icon));
