import { appClient } from '../utils/appClient';
import type { Branding } from './brandingTypes';
import { default as gsbBrand } from './gsb';
import { default as cxnplBrand } from './cxnpl';
import { default as myobBrand } from './myob';

// @ts-expect-error -- we will assign the values later
const brand: Branding = {};

// TODO - PWA-1356
const brandName = appClient === 'WEB' ? process.env.BRAND_NAME : process.env.EXPO_PUBLIC_BRAND_NAME;

// Use if/else instead of the switch statement to tree-shake the unused code branches
if (brandName === 'gsb') {
  Object.assign(brand, gsbBrand);
} else if (brandName === 'myob') {
  Object.assign(brand, myobBrand);
} else {
  Object.assign(brand, cxnplBrand);
}

export default brand;
