import type { IconProps } from '@tamagui/helpers-icon';
import type { NamedExoticComponent, ReactNode } from 'react';
import type { StackProps, TamaguiComponent } from 'tamagui';
import { createStyledContext } from 'tamagui';

/**
 * Public API for a Toggle component
 */
export interface ToggleProps extends Pick<StackProps, 'maxWidth'> {
  focusable?: boolean;
  children: ReactNode;
  defaultValue?: string;
  disabled?: boolean;
  pressed?: boolean;
  defaultPressed?: boolean;
  onPressedChange: (pressed: boolean) => void;
  icon?: TamaguiComponent<IconProps> | NamedExoticComponent<IconProps>;
  size?: 'md' | 'lg';
  variant: 'button' | 'card';
  alignment?: 'row' | 'column';
  circular?: boolean;
  testID?: string;
}

// Util types for toggle
type TamaguiButtonElement = HTMLButtonElement;
export type ToggleElement = TamaguiButtonElement;

interface ToggleContextValues {
  variant: 'card' | 'button';
  alignment: 'row' | 'column';
  disabled: boolean;
  active: boolean;
}

// Styled default context for toggles to use
export const ToggleStyledContext = createStyledContext<ToggleContextValues>({
  variant: 'card',
  alignment: 'row',
  disabled: false,
  active: false,
});
