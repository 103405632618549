import { isWeb, styled, TextArea } from 'tamagui';
import { shadowOpacity, shadowRadius } from '../../tokens/shadow';
import { getTextAreaBaseVariantTokens } from './types';

export const TextAreaBaseWeb = styled(TextArea, {
  name: 'TextArea',
  fontFamily: '$bodyEmphasised',
  borderRadius: '$radius.form/radius/formcontrol',
  borderWidth: '$size.input/size/border-width',
  borderColor: '$form/color/form-border-default',
  color: '$form/color/form-fg-default',
  backgroundColor: '$background/transparent',
  minWidth: 0,
  placeholderTextColor: '$form/color/form-fg-subdued',
  hoverStyle: {},
  focusStyle: {
    borderColor: '$background/transparent',
    shadowColor: '$form/color/form-border-selected',
    shadowOpacity: shadowOpacity.on,
    shadowRadius: shadowRadius.on,

    outlineColor: '$form/color/form-border-selected',
    outlineWidth: '$size.input/size/border-focus-width',
    outlineStyle: 'solid',
  },
  tabIndex: 0,
  variants: {
    status: {
      default: {
        borderColor: '$form/color/form-border-default',
      },
      success: {
        borderColor: '$form/color/form-border-default',
      },
      error: {
        borderColor: '$form/color/form-border-danger',
      },
    },
    size: {
      $sm: getTextAreaBaseVariantTokens('$sm'),
      $md: getTextAreaBaseVariantTokens('$md'),
      $lg: getTextAreaBaseVariantTokens('$lg'),
      $xl: getTextAreaBaseVariantTokens('$xl'),
    },
    textAreaDisabled: {
      true: {
        backgroundColor: '$background/disabled',
        color: '$foreground/disabled',
      },
    },
  } as const,

  defaultVariants: {
    status: 'default',
  },
});

export const TextAreaBaseNative = styled(TextAreaBaseWeb, {
  borderColor: '$form/color/form-border-default',
  borderRadius: '$radius.form/radius/formcontrol',
  borderWidth: '$size.input/size/border-width',
  scrollEnabled: true,
  multiline: true,
  focusStyle: {
    borderColor: '$form/color/form-border-selected',
    shadowColor: '$form/color/form-border-selected',
    shadowOpacity: shadowOpacity.on,
    shadowRadius: shadowRadius.on,

    outlineColor: '$form/color/form-border-selected',
    outlineStyle: 'solid',
    outlineWidth: '$size.input/size/border-width',
    outlineOffset: '$size.input/size/border-width',
    backgroundColor: isWeb ? '$background/transparent' : '$background/surface', // To avoid text ghosting effect
  },
});
