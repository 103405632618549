'use client';

/* eslint-disable @typescript-eslint/prefer-optional-chain, no-nested-ternary -- This is fine and it's better for the logic desired here */

import { useMemo, useId, useRef } from 'react';
import { useFieldInfo, useTsController } from '@ts-react/form';
import { useTranslation } from 'react-i18next';
import { Fieldset, YStack, getTokenValue, XStack } from 'tamagui';
import type { SpaceValue, ThemeValueFallback, ThemeValueFallbackSpace } from '@tamagui/web';
import { ThumbsDown, ThumbsUp } from '@cxnpl/ui/icons';
import { columnMaxWidth } from '../../../utils';
import { Alert } from '../../Alert';
import { Label } from '../../Label';
import type { ToggleGroupProps } from '../../ToggleGroup';
import { ToggleGroup } from '../../ToggleGroup';
import { Popover } from '../../Popover';
import { Text } from '../../Text';
import type { ToggleMediaProps } from './ToggleGroupField';

const DEFAULT_GAP = 8;

export interface ToggleGroupBooleanFieldProps
  extends Pick<ToggleGroupProps, 'testID' | 'disabled' | 'variant' | 'size'> {
  onPressYes?: () => void;
  onPressNo?: () => void;
  columns?: number;
  info?: { title: string; body: string };
  $mobile?: ToggleMediaProps;
  $tablet?: ToggleMediaProps;
  $laptop?: ToggleMediaProps;
  $desktop?: ToggleMediaProps;
  /**
   * Restricted to only numbers or tokens for the sake of flexibility but not going crazy with all the CSS or Variable options
   */
  gap?: Exclude<SpaceValue, ThemeValueFallback | ThemeValueFallbackSpace | boolean>;
  /**
   * Restricted to only numbers or tokens for the sake of flexibility but not going crazy with all the CSS or Variable options
   */
  columnGap?: Exclude<SpaceValue, ThemeValueFallback | ThemeValueFallbackSpace | boolean>;
}

export const ToggleGroupBooleanField = ({
  size = 'lg',
  testID,
  disabled,
  variant,
  onPressYes,
  onPressNo,
  columns = 1,
  info,
  $tablet,
  $laptop,
  $desktop,
  gap,
  columnGap,
}: ToggleGroupBooleanFieldProps) => {
  const { t } = useTranslation();
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<boolean>();
  const id = useId();
  const isDisabled = isSubmitting || disabled;

  const toggleGroupRef = useRef(null);

  const zodFieldInfo = useFieldInfo();
  const { label } = zodFieldInfo;

  /**
   * calculate column gap in pixels for each of the fields' maxWidth
   */
  const columnGapPixels: number = useMemo(() => {
    //Priority is from more specific columnGap to less specific gap to default
    const baseGap: SpaceValue = columnGap ? columnGap : gap ? gap : DEFAULT_GAP;

    if (typeof baseGap === 'number') {
      return baseGap;
    }
    const tokenValue = getTokenValue(baseGap, 'space');
    return tokenValue as number;
  }, [gap, columnGap]);

  const content = [
    {
      label: t('common.buttons.yes'),
      value: 'true',
      icon: variant === 'card' ? ThumbsUp : undefined,
    },
    {
      label: t('common.buttons.no'),
      value: 'false',
      icon: variant === 'card' ? ThumbsDown : undefined,
    },
  ];

  const getValue = () => {
    if (field.value === undefined) {
      return undefined;
    }
    return field.value ? 'true' : 'false';
  };

  return (
    <Fieldset>
      <YStack gap="$space.md">
        {Boolean(label) && (
          <XStack gap="$sm" alignItems="center">
            <Label
              htmlFor={id}
              testID={`${testID || field.name}-label`}
              variant="bodyMediumEm"
              color="$form/color/form-fg-default"
            >
              {label}
            </Label>
            {info ? (
              <Popover title={info.title} targetElement="info">
                <Text variant="bodySmall" color="$text/surface-inverse">
                  {info.body}
                </Text>
              </Popover>
            ) : null}
          </XStack>
        )}
        {error?.errorMessage ? (
          <Alert severity="danger" variant="inline-transparent">
            {error.errorMessage}
          </Alert>
        ) : null}
        <ToggleGroup
          ref={toggleGroupRef}
          id={id}
          testID={`${testID || field.name}-toggle-group`}
          disabled={isDisabled}
          size={size}
          type="single"
          variant={variant}
          value={getValue()}
          onValueChange={(value) => {
            field.onChange(value === 'true');
            if (value === 'true') {
              onPressYes?.();
            } else {
              onPressNo?.();
            }
          }}
          gap={gap ? gap : DEFAULT_GAP}
          columnGap={columnGap ? columnGap : DEFAULT_GAP}
        >
          {content.map((item) => {
            return (
              <ToggleGroup.Item
                testID={`${testID || field.name}-${item.label}-toggle-item`}
                key={item.value}
                value={item.value}
                icon={item.icon}
                maxWidth={columnMaxWidth(columnGapPixels, columns)}
                $tablet={
                  $tablet && $tablet.columns
                    ? {
                        maxWidth: columnMaxWidth(columnGapPixels, $tablet.columns),
                      }
                    : undefined
                }
                $laptop={
                  $laptop && $laptop.columns
                    ? {
                        maxWidth: columnMaxWidth(columnGapPixels, $laptop.columns),
                      }
                    : undefined
                }
                $desktop={
                  $desktop && $desktop.columns
                    ? {
                        maxWidth: columnMaxWidth(columnGapPixels, $desktop.columns),
                      }
                    : undefined
                }
              >
                {item.label}
              </ToggleGroup.Item>
            );
          })}
        </ToggleGroup>
      </YStack>
    </Fieldset>
  );
};
