'use client';
import { createStyledContext, styled, withStaticProperties } from '@tamagui/web';
import { Spinner, Stack, XStack } from 'tamagui';
import { FileText } from '@tamagui/lucide-icons';
import type { ChangeEvent } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../Text';

interface WebFileUploadContextValues {
  disabled?: boolean;
}

// All these values will be passed into child components of ButtonBase
const WebFileUploadContext = createStyledContext<WebFileUploadContextValues>({
  disabled: false,
});

//Frame for main text and decimal component to sit in
const WebFileUploadDragDropFrame = styled(Stack, {
  name: 'WebFileUpload',
  context: WebFileUploadContext,
  tag: 'div',
  width: '100%',
  height: '$doc-upload/size/web-upload-height',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$space.lg',
  borderRadius: '$toggle/radius/toggle-radius-multi',
  borderStyle: 'dashed',
  borderWidth: 1,
  variants: {
    draggingItem: {
      true: {
        backgroundColor: '$background/primary-subdued',
      },
      false: {
        backgroundColor: '$background/transparent',
      },
    },
    error: {
      true: {
        borderColor: '$border/danger',
      },
      false: {
        borderColor: '$border/app',
      },
    },
    disabled: {
      true: {
        pointerEvents: 'none',
        userSelect: 'none',
        cursor: 'not-allowed',
        backgroundColor: '$background/disabled',
      },
    },
  },
} as const);

//Frame for just text and icons
const TextIconFrame = styled(Stack, {
  name: 'TextIconFrame',
  context: WebFileUploadContext,
  focusable: true,
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  gap: '$space.sm',
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        userSelect: 'none',
        cursor: 'not-allowed',
        backgroundColor: '$background/disabled',
      },
    },
  },
} as const);

//UploadIcon
const UpLoadIcon = () => {
  return <FileText size={16} />;
};

//Main component
const WebFileUploadBase = withStaticProperties(WebFileUploadDragDropFrame, {
  TextIconFrame,
  UploadText: Text,
  UploadIcon: UpLoadIcon,
});

//Props
interface WebFileUploadProps {
  /**
   * Test ID
   */
  testId?: string;
  /**
   * A list of accepted file types.
   * More information: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept#unique_file_type_specifiers
   */
  fileTypes: string[];
  /**
   * Processing file boolean to change style
   */
  processingFile: boolean;
  /**
   * Callback to return file data for processing
   */
  onChangeUpload: (files: FileList | null) => Promise<void>;
  /**
   * Error to change display of border
   */
  error?: boolean;
  /**
   * Whether file upload is disabled
   */
  disabled?: boolean;
  /**
   * Whether file input should accept multiple files selected at once
   */
  multiple?: boolean;
}

//Web file upload component
export const WebFileUpload = ({
  testId = 'webDocumentUpload',
  fileTypes,
  processingFile,
  onChangeUpload,
  error,
  disabled,
  multiple = true,
}: WebFileUploadProps) => {
  const { t } = useTranslation();

  const fileTypesString = fileTypes.join(',');
  const [draggingItem, setDraggingItem] = useState(false);
  const fileInputField = useRef<HTMLInputElement>(null);

  //Drive file input from the main component
  const onChangeClick = () => {
    if (fileInputField.current !== null) {
      fileInputField.current.click();
    }
  };

  //Handle upload item
  const onChangeHandleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const uploadedFiles: FileList | null = event.target.files;
    await onChangeUpload(uploadedFiles);
    event.target.value = ''; //Reset the input to enable onChange to reupload the same file
  };

  //Handle drag and drop item
  const onChangeHandleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles: FileList = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const filteredFiles: File[] = Array.from(droppedFiles).filter((file: File) => {
        //Filter out files not matching correct type.
        //Passing file types to an input tag is already case insensitive.
        //However, this is the callback for drag and dropped files, so we need to explicitly add case insensitivity
        return fileTypes.some((item) => file.name.toLowerCase().endsWith(item.toLowerCase()));
      });
      if (filteredFiles.length !== droppedFiles.length) {
        await onChangeUpload(null);
      } else {
        const dataTransfer = new DataTransfer();
        filteredFiles.forEach((file: File) => dataTransfer.items.add(file));
        await onChangeUpload(dataTransfer.files);
      }
    }
  };

  return (
    <div
      onDragOver={(event) => {
        event.preventDefault();
        setDraggingItem(true);
      }}
      onDragLeave={() => {
        if (disabled) {
          return;
        }
        setDraggingItem(false);
      }}
      onDropCapture={() => {
        if (disabled) {
          return;
        }
        setDraggingItem(false);
      }}
      onDrop={(event: React.DragEvent<HTMLDivElement>) => void onChangeHandleDrop(event)}
    >
      <WebFileUploadBase testID={testId} draggingItem={draggingItem} error={error} disabled={disabled}>
        <input
          data-testID={`${testId}-input`}
          type="file"
          style={{ display: 'none' }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            void onChangeHandleUpload(event);
          }}
          ref={fileInputField}
          id={`${testId}-input`}
          accept={fileTypesString}
          multiple={multiple}
          disabled={disabled}
        />
        <WebFileUploadBase.TextIconFrame>
          {processingFile ? (
            <>
              <Spinner size="small" color="$foreground/primary-default" />
              <WebFileUploadBase.UploadText testID={`${testId}-fileUploadingText`} variant="bodyMedium">
                {t('fields.fileUpload.processing')}
              </WebFileUploadBase.UploadText>
            </>
          ) : (
            <>
              <UpLoadIcon />
              <XStack gap="$space.xs">
                <WebFileUploadBase.UploadText testID={`${testId}-dragDropText`} variant="bodyMedium">
                  {t('fields.fileUpload.dragFiles')}
                </WebFileUploadBase.UploadText>
                <WebFileUploadBase.UploadText
                  cursor="pointer"
                  onPress={() => {
                    if (disabled) {
                      return;
                    }
                    onChangeClick();
                  }}
                  variant="linkMedium"
                  color="$text/primary"
                >
                  {t('fields.fileUpload.browse')}
                </WebFileUploadBase.UploadText>
              </XStack>
            </>
          )}
        </WebFileUploadBase.TextIconFrame>
      </WebFileUploadBase>
    </div>
  );
};
