/* eslint-disable @typescript-eslint/no-unsafe-return -- from Tamagui implementation */
/* eslint-disable @typescript-eslint/no-unsafe-member-access -- from Tamagui implementation */
/* eslint-disable @typescript-eslint/no-non-null-assertion -- from Tamagui implementation */
/* eslint-disable @typescript-eslint/ban-ts-comment -- from Tamagui implementation */
/* eslint-disable @typescript-eslint/prefer-ts-expect-error -- from Tamagui implementation */
/* eslint-disable @typescript-eslint/no-explicit-any -- from Tamagui implementation */
import type { GetProps } from 'tamagui';
import { Adapt, useAdaptParent } from '@tamagui/adapt';
import { useComposedRefs } from '@tamagui/compose-refs';
import { isWeb, useIsomorphicLayoutEffect } from '@tamagui/constants';
import type { FontSizeTokens, SizeTokens, TamaguiComponent, TamaguiElement } from '@tamagui/core';
import { styled, useEvent, useGet, withStaticProperties } from '@tamagui/core';
import { getSpace } from '@tamagui/get-token';
import type { ListItemProps } from '@tamagui/list-item';
import { ListItem } from '@tamagui/list-item';
import { PortalHost } from '@tamagui/portal';
import { Separator } from '@tamagui/separator';
import { Sheet, SheetController } from '@tamagui/sheet';
import type { XStackProps, YStackProps } from '@tamagui/stacks';
import { ThemeableStack, XStack, YStack } from '@tamagui/stacks';
import { Paragraph } from '@tamagui/text';
import { useControllableState } from '@tamagui/use-controllable-state';
import * as React from 'react';
import { Text } from '../Text';
import { SELECT_NAME } from './constants';
import {
  SelectItemParentProvider,
  SelectProvider,
  createSelectContext,
  useSelectContext,
  useSelectItemParentContext,
} from './context';
import { SelectContent } from './SelectContent';
import { SelectInlineImpl } from './SelectImpl';
import { SelectItem, useSelectItemContext } from './SelectItem';
import { ITEM_TEXT_NAME, SelectItemText } from './SelectItemText';
import { SelectScrollDownButton, SelectScrollUpButton } from './SelectScrollButton';
import { SelectTrigger } from './SelectTrigger';
import { SelectViewport } from './SelectViewport';
import type { ScopedProps, SelectImplProps, SelectBaseProps } from './types';
import { useSelectBreakpointActive, useShowSelectSheet } from './useSelectBreakpointActive';

/* -------------------------------------------------------------------------------------------------
 * SelectValue
 * ## The selected text and its surroundings ##
 * -----------------------------------------------------------------------------------------------*/

const VALUE_NAME = 'SelectValue';

const SelectValueFrame = styled(Text, {
  name: VALUE_NAME,
  userSelect: 'none',
});

export interface SelectValueExtraProps {
  placeholder?: React.ReactNode;
}

type SelectValueProps = GetProps<typeof SelectValueFrame> & SelectValueExtraProps;

//TODO: move to external file and add unit tests
const parentSizeToFont: (parentSize: SizeTokens | undefined) => '$sm' | '$md' = (parentSize) => {
  switch (parentSize) {
    case '$sm':
    case '$md':
      return '$sm';
    default:
      return '$md';
  }
};

const SelectValue = SelectValueFrame.styleable<SelectValueExtraProps>(function SelectValue(
  { __scopeSelect, children: childrenProp, placeholder, ...props }: ScopedProps<SelectValueProps>,
  forwardedRef
) {
  // We ignore `className` and `style` as this part shouldn't be styled.
  const context = useSelectContext(VALUE_NAME, __scopeSelect);
  const itemParentContext = useSelectItemParentContext(VALUE_NAME, __scopeSelect);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- from Tamagui implementation
  const composedRefs = useComposedRefs(forwardedRef, context.onValueNodeChange);
  const children = childrenProp ?? context.selectedItem;
  // eslint-disable-next-line eqeqeq -- from Tamagui implementation
  const isEmptyValue = context.value == null || context.value === '';
  const selectValueChildren = isEmptyValue ? placeholder ?? children : children;
  // Adapt the font
  const fontModifiers = parentSizeToFont(itemParentContext.size);

  return (
    <SelectValueFrame
      {...(!props.unstyled && {
        size: itemParentContext.size as any,
        ellipse: true,
        // we don't want events from the portalled `SelectValue` children to bubble
        // through the item they came from
        pointerEvents: 'none',
        fontFamily: '$bodyEmphasised',
        fontWeight: fontModifiers,
        fontSize: fontModifiers,
        lineHeight: fontModifiers,
        width: '90%', // What is this 90% for? Was there some visual issue and this was added to cover for it instead of fixing the underlying issue?
      })}
      ref={composedRefs}
      {...props}
    >
      {unwrapSelectItem(selectValueChildren)}
    </SelectValueFrame>
  );
});

function unwrapSelectItem(selectValueChildren: any): any {
  return React.Children.map(selectValueChildren, (child) => {
    if (child) {
      if (child.type?.staticConfig?.componentName === ITEM_TEXT_NAME) {
        return child.props.children;
      }
      if (child.props?.children) {
        return unwrapSelectItem(child.props.children);
      }
    }
    return child;
  });
}

/* -------------------------------------------------------------------------------------------------
 * SelectIcon
 * -----------------------------------------------------------------------------------------------*/

export const SelectIcon: TamaguiComponent<XStackProps> = styled(XStack, {
  name: 'SelectIcon',
  // @ts-ignore
  'aria-hidden': true,
  children: <Paragraph>▼</Paragraph>,
});

/* -------------------------------------------------------------------------------------------------
 * SelectItemIndicator
 * -----------------------------------------------------------------------------------------------*/

const ITEM_INDICATOR_NAME = 'SelectItemIndicator';

const SelectItemIndicatorFrame = styled(XStack, {
  name: ITEM_TEXT_NAME,
});

type SelectItemIndicatorProps = GetProps<typeof SelectItemIndicatorFrame>;

const SelectItemIndicator = React.forwardRef<TamaguiElement, SelectItemIndicatorProps>(
  (props: ScopedProps<SelectItemIndicatorProps>, forwardedRef) => {
    const { __scopeSelect, ...itemIndicatorProps } = props;
    const context = useSelectItemParentContext(ITEM_INDICATOR_NAME, __scopeSelect);
    const itemContext = useSelectItemContext(ITEM_INDICATOR_NAME, __scopeSelect);

    if (context.shouldRenderWebNative) {
      return null;
    }

    return itemContext.isSelected ? (
      <SelectItemIndicatorFrame aria-hidden {...itemIndicatorProps} ref={forwardedRef} />
    ) : null;
  }
);

SelectItemIndicator.displayName = ITEM_INDICATOR_NAME;

/* -------------------------------------------------------------------------------------------------
 * SelectGroup
 * -----------------------------------------------------------------------------------------------*/

const GROUP_NAME = 'SelectGroup';

interface SelectGroupContextValue {
  id: string;
}

const [SelectGroupContextProvider, useSelectGroupContext] = createSelectContext<SelectGroupContextValue>(GROUP_NAME);

export const SelectGroupFrame: TamaguiComponent<YStackProps> = styled(YStack, {
  name: GROUP_NAME,
  width: '100%',
});

const NativeSelectTextFrame = styled(Text, {
  tag: 'select',
});

const NativeSelectFrame = styled(ThemeableStack, {
  name: 'NativeSelect',

  bordered: true,
  userSelect: 'none',
  outlineWidth: 0,
  paddingRight: 10,
  borderRadius: '$form/radius/formcontrol',

  variants: {
    size: {
      '...size': (val /*extras*/) => {
        //const { tokens } = extras;
        //const paddingHorizontal = getVariableValue(tokens.space[val]);

        return {
          //minHeight: tokens.size[val],
          //paddingRight: paddingHorizontal + 20,
          //paddingLeft: paddingHorizontal,
          paddingVertical: getSpace(val, {
            shift: -3,
          }),
        };
      },
    },
  } as const,

  defaultVariants: {
    size: '$lg',
  },
});

type SelectGroupProps = GetProps<typeof SelectGroupFrame>;

const SelectGroup = React.forwardRef<TamaguiElement, SelectGroupProps>(
  (props: ScopedProps<SelectGroupProps>, forwardedRef) => {
    const { __scopeSelect, ...groupProps } = props;
    const groupId = React.useId();

    const context = useSelectContext(GROUP_NAME, __scopeSelect);
    const itemParentContext = useSelectItemParentContext(GROUP_NAME, __scopeSelect);
    const size = itemParentContext.size ?? '$true';
    const nativeSelectRef = React.useRef<HTMLSelectElement>(null);

    const content = (() => {
      if (itemParentContext.shouldRenderWebNative) {
        return (
          // @ts-expect-error until we support typing based on tag
          <NativeSelectFrame asChild size={size} value={context.value}>
            <NativeSelectTextFrame
              // @ts-ignore it's ok since tag="select"
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                itemParentContext.onChange(event.currentTarget.value);
              }}
              size={size as FontSizeTokens}
              ref={nativeSelectRef}
              style={{
                // @ts-ignore
                appearance: 'none',
              }}
            >
              {props.children}
            </NativeSelectTextFrame>
          </NativeSelectFrame>
        );
      }
      return (
        <SelectGroupFrame
          // @ts-ignore
          role="group"
          aria-labelledby={groupId}
          {...groupProps}
          ref={forwardedRef}
        />
      );
    })();

    return (
      <SelectGroupContextProvider scope={__scopeSelect} id={groupId || ''}>
        {content}
      </SelectGroupContextProvider>
    );
  }
);

SelectGroup.displayName = GROUP_NAME;

/* -------------------------------------------------------------------------------------------------
 * SelectLabel
 * ## This one is the one at the top of the opened panel ##
 * -----------------------------------------------------------------------------------------------*/

const LABEL_NAME = 'SelectLabel';

export type SelectLabelProps = ListItemProps;

const SelectLabel = React.forwardRef<TamaguiElement, SelectLabelProps>(
  (props: ScopedProps<SelectLabelProps>, forwardedRef) => {
    const { __scopeSelect, ...labelProps } = props;
    const context = useSelectItemParentContext(LABEL_NAME, __scopeSelect);
    const groupContext = useSelectGroupContext(LABEL_NAME, __scopeSelect);
    const fontModifiers = parentSizeToFont(context.size);

    if (context.shouldRenderWebNative) {
      return null;
    }

    return (
      <ListItem
        tag="div"
        componentName={LABEL_NAME}
        id={groupContext.id}
        size={context.size}
        {...labelProps}
        ref={forwardedRef}
        backgrounded
        backgroundColor="$background/surface" //TODO: the form background color token is wrong because it's transparent, it can't be used
        fontFamily="$bodyEmphasised"
        fontSize={fontModifiers}
        fontWeight={fontModifiers}
        style={{}}
      />
    );
  }
);

SelectLabel.displayName = LABEL_NAME;

/* -------------------------------------------------------------------------------------------------
 * SelectSeparator
 * -----------------------------------------------------------------------------------------------*/

/*
The inferred type of 'SelectSeparator' cannot be named without a reference to '@tamagui/text/node_modules/@tamagui/web'.
 This is likely not portable. A type annotation is necessary.ts(2742)
*/

type SeparatorProps = GetProps<typeof Separator>;

export const SelectSeparator: TamaguiComponent<SeparatorProps> = styled(Separator, {
  name: 'SelectSeparator',
});

const SelectSheetController = (
  // eslint-disable-next-line @typescript-eslint/ban-types -- from Tamagui implementation
  props: ScopedProps<{}> & {
    children: React.ReactNode;
    onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
  }
) => {
  const context = useSelectContext('SelectSheetController', props.__scopeSelect);
  const showSheet = useShowSelectSheet(context);
  const breakpointActive = useSelectBreakpointActive(context.sheetBreakpoint);
  const getShowSheet = useGet(showSheet);

  return (
    <SheetController
      onOpenChange={(val: boolean | ((prevState: boolean) => boolean)) => {
        if (getShowSheet()) {
          props.onOpenChange(val);
        }
      }}
      open={context.open}
      hidden={!breakpointActive}
    >
      {props.children}
    </SheetController>
  );
};

const SelectSheetImpl = (props: SelectImplProps) => {
  return <>{props.children}</>;
};

/* -------------------------------------------------------------------------------------------------
 * SelectBase
 * ## All the components to create a Select
 * -----------------------------------------------------------------------------------------------*/
export const SelectBase: any = withStaticProperties(
  (props: ScopedProps<SelectBaseProps>) => {
    const {
      __scopeSelect,
      native,
      children,
      open: openProp,
      defaultOpen,
      onOpenChange,
      value: valueProp,
      defaultValue,
      onValueChange,
      disablePreventBodyScroll,
      size: sizeProp = '$lg',
      onActiveChange,
      dir,
      id,
    } = props;

    const internalId = React.useId();
    const scopeKey = __scopeSelect ? Object.keys(__scopeSelect)[0] ?? internalId : internalId;

    const { when, AdaptProvider } = useAdaptParent({
      Contents: React.useCallback(() => <PortalHost name={`${scopeKey}SheetContents`} />, [scopeKey]),
    });

    const sheetBreakpoint = when;
    const isSheet = useSelectBreakpointActive(sheetBreakpoint);
    const SelectImpl = isSheet || !isWeb ? SelectSheetImpl : SelectInlineImpl;
    const forceUpdate = React.useReducer(() => ({}), {})[1];
    const [selectedItem, setSelectedItem] = React.useState<React.ReactNode>(null);

    const [open, setOpen] = useControllableState({
      prop: openProp,
      defaultProp: defaultOpen || false,
      onChange: onOpenChange,
    });

    const [value, setValue] = useControllableState({
      prop: valueProp,
      defaultProp: defaultValue || '',
      onChange: onValueChange,
      transition: true,
    });

    React.useEffect(() => {
      if (open) {
        emitValue(value);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps -- from Tamagui implementation
    }, [open]);

    React.useEffect(() => {
      emitValue(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps -- from Tamagui implementation
    }, [value]);

    const [activeIndex, setActiveIndex] = React.useState<number | null>(0);

    const [emitValue, valueSubscribe] = useEmitter<any>();
    const [emitActiveIndex, activeIndexSubscribe] = useEmitter<number>();

    const selectedIndexRef = React.useRef<number | null>(null);
    const activeIndexRef = React.useRef<number | null>(null);
    const listContentRef = React.useRef<string[]>([]);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [valueNode, setValueNode] = React.useState<HTMLElement | null>(null);

    useIsomorphicLayoutEffect(() => {
      selectedIndexRef.current = selectedIndex;
      activeIndexRef.current = activeIndex;
    });

    const shouldRenderWebNative =
      isWeb && (native === true || native === 'web' || (Array.isArray(native) && native.includes('web')));

    return (
      <AdaptProvider>
        <SelectItemParentProvider
          scope={__scopeSelect}
          initialValue={React.useMemo(
            () => value,
            // eslint-disable-next-line react-hooks/exhaustive-deps -- from Tamagui implementation
            [open]
          )}
          size={sizeProp}
          activeIndexSubscribe={activeIndexSubscribe}
          valueSubscribe={valueSubscribe}
          setOpen={setOpen}
          id={id}
          onChange={React.useCallback(
            (val) => {
              setValue(val);
              emitValue(val);
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps -- from Tamagui implementation
            []
          )}
          onActiveChange={useEvent((...args) => {
            onActiveChange?.(...args);
          })}
          setSelectedIndex={setSelectedIndex}
          // eslint-disable-next-line @typescript-eslint/no-shadow -- from Tamagui implementation
          setValueAtIndex={React.useCallback((index, value) => {
            listContentRef.current[index] = value;
          }, [])}
          shouldRenderWebNative={shouldRenderWebNative}
        >
          <SelectProvider
            scope={__scopeSelect}
            disablePreventBodyScroll={disablePreventBodyScroll}
            dir={dir}
            blockSelection={false}
            fallback={false}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            forceUpdate={forceUpdate}
            valueNode={valueNode}
            onValueNodeChange={setValueNode}
            scopeKey={scopeKey}
            sheetBreakpoint={sheetBreakpoint}
            activeIndex={activeIndex}
            selectedIndex={selectedIndex}
            setActiveIndex={React.useCallback((index) => {
              setActiveIndex((prev) => {
                if (prev !== index) {
                  if (typeof index === 'number') {
                    emitActiveIndex(index);
                  }
                  return index;
                }
                return prev;
              });
              // eslint-disable-next-line react-hooks/exhaustive-deps -- from Tamagui implementation
            }, [])}
            value={value}
            open={open}
            native={native}
          >
            <SelectSheetController onOpenChange={setOpen} __scopeSelect={__scopeSelect}>
              {shouldRenderWebNative ? (
                children
              ) : (
                <SelectImpl
                  activeIndexRef={activeIndexRef}
                  listContentRef={listContentRef}
                  selectedIndexRef={selectedIndexRef}
                  {...props}
                  open={open}
                  value={value}
                >
                  {children}
                </SelectImpl>
              )}
            </SelectSheetController>
          </SelectProvider>
        </SelectItemParentProvider>
      </AdaptProvider>
    );
  },
  {
    Adapt,
    Content: SelectContent,
    Group: SelectGroup,
    Icon: SelectIcon,
    Item: SelectItem,
    ItemIndicator: SelectItemIndicator,
    ItemText: SelectItemText,
    Label: SelectLabel,
    ScrollDownButton: SelectScrollDownButton,
    ScrollUpButton: SelectScrollUpButton,
    Trigger: SelectTrigger,
    Value: SelectValue,
    Viewport: SelectViewport,
    Sheet: Sheet.Controlled,
  }
);

function useEmitter<A>() {
  // eslint-disable-next-line @typescript-eslint/ban-types -- from Tamagui implementation
  const listeners = React.useRef<Set<Function>>();
  if (!listeners.current) {
    listeners.current = new Set();
  }
  const emit = (value: A) => {
    listeners.current!.forEach((l) => l(value));
  };
  const subscribe = React.useCallback((listener: (val: A) => void) => {
    listeners.current!.add(listener);
    return () => {
      listeners.current!.delete(listener);
    };
  }, []);
  return [emit, subscribe] as const;
}

// @ts-ignore
SelectBase.displayName = SELECT_NAME;
