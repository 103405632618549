type LoanDocumentCategory =
  | 'BANK_STATEMENTS'
  | 'BAS'
  | 'TAX_RETURN'
  | 'VEHICLE_TAX_INVOICE'
  | 'FINAL_TAX_INVOICE'
  | 'INSURANCE'
  | 'ATO_PORTAL_STATEMENT'
  | 'NOTICE_OF_ASSESSMENT'
  | 'OTHER_DOCUMENTS'
  | 'SELFIE'
  | 'DRIVER_LICENSE'
  | 'MANAGEMENT_ACCOUNT';

type OnboardingDocumentCategory = 'PARTNERSHIP_DEED' | 'TRUST_DEED' | 'TRUST_UNIT_HOLDERS_REGISTER';

type ABAFileDocumentCategory = 'ABA';

export type DocumentCategory = LoanDocumentCategory | OnboardingDocumentCategory | ABAFileDocumentCategory;

//Doc format for sops
export interface Document {
  fileName: string;
  category: string;
  data: Uint8Array;
  size: number;
  type: string;
  visibleToUser?: boolean;
}

//Document from input element
export interface InputDocument {
  fileName: string;
  category: string;
  data: ArrayBuffer;
  size: number;
  type: string;
}

//Custom type for form render element
export interface UploadedFile {
  id: string;
  name: string;
}

//Props for file upload
export interface FileUploadProps {
  testId?: string;
  /**
   * Metadata for files that have been uploaded. The list of names to be displayed.
   */
  files: UploadedFile[];
  /**
   * The category of the documents
   */
  category: DocumentCategory;
  /**
   * Types of files allowed
   */
  fileTypes: string[];
  /**
   * Error message to display
   */
  errorMessage?: string | undefined;
  /**
   * Remove a file by file name
   */
  onDeleteDoc?: (fileId: string) => Promise<void> | void;
  /**
   * Add a file by file name
   */
  onAddDoc: (formattedFiles: Document[]) => Promise<void> | void;
  /**
   * File upload is invalid. e.g wrong format from drag and drop - error in
   */
  onInvalidFileUploaded: (category: string, message: string) => void;
  /**
   * Whether file upload is disabled
   */
  disabled?: boolean;
  /**
   * Whether file input should accept multiple files selected at once
   */
  multiple?: boolean;
  /**
   * Only show the file upload component
   */
  showFileUploadOnly?: boolean;
}

export const FILE_SIZE_LIMIT = 5000000; // 5MB
