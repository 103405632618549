'use client';

import { ToastProvider as TamaguiToastProvider, Toast, ToastViewport, useToastState } from '@tamagui/toast';
import { Text } from '@cxnpl/ui';
import type { ToastProviderProps } from './types';
import { toastIconMap, ToastView } from './components';

const CurrentToastWeb = () => {
  const currentToast = useToastState();

  if (!currentToast || currentToast.isHandledNatively) {
    return null;
  }
  const severity = currentToast.customData?.severity as 'danger' | 'info' | 'success' | 'warning' | 'attention';

  return (
    <Toast
      key={currentToast.id}
      duration={currentToast.duration}
      enterStyle={{ opacity: 0, scale: 0.5, y: -25 }}
      exitStyle={{ opacity: 0, scale: 1, y: -20 }}
      y={0}
      opacity={1}
      scale={1}
      animation="100ms"
      viewportName="viewport-web-toast"
      testID="toast-alert"
    >
      <ToastView severity={severity}>
        {toastIconMap[severity]}
        <Text variant="bodySmallEm">{currentToast.title}</Text>
      </ToastView>
    </Toast>
  );
};

export function ToastProvider({ children }: ToastProviderProps) {
  return (
    <TamaguiToastProvider burntOptions={{ from: 'bottom' }}>
      <CurrentToastWeb />
      {children}
      <ToastViewport name="viewport-web-toast" top="$md" left={0} right={0} />
    </TamaguiToastProvider>
  );
}
