'use client';

import type { ReactNode } from 'react';
import { useAutomaticRefreshToken } from './useAutomaticRefreshToken';
import { useInitializeTokenInfo } from './useInitializeTokenInfo';

export function TokenProvider({ children }: { children: ReactNode }) {
  useAutomaticRefreshToken();
  useInitializeTokenInfo(); // This is needed in case the user has hard refreshed the page via the browser after logging in

  return <>{children}</>;
}
