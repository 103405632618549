/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { CreateLoginIssueCaseBody, IntercomHmac, RecordFaqCategoryFaqArray } from '../api.schemas';
import { customAxios } from '../../axios';

/**
 * Gets the FAQs
 */
export const getFaqs = (signal?: AbortSignal) => {
  return customAxios<RecordFaqCategoryFaqArray>({ url: `/help/faqs`, method: 'GET', signal });
};

export const getGetFaqsQueryKey = () => {
  return [`/help/faqs`] as const;
};

export const getGetFaqsQueryOptions = <TData = Awaited<ReturnType<typeof getFaqs>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFaqs>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFaqsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFaqs>>> = ({ signal }) => getFaqs(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFaqs>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetFaqsQueryResult = NonNullable<Awaited<ReturnType<typeof getFaqs>>>;
export type GetFaqsQueryError = unknown;

export function useGetFaqs<TData = Awaited<ReturnType<typeof getFaqs>>, TError = unknown>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFaqs>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getFaqs>>, TError, TData>, 'initialData'>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetFaqs<TData = Awaited<ReturnType<typeof getFaqs>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFaqs>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getFaqs>>, TError, TData>, 'initialData'>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetFaqs<TData = Awaited<ReturnType<typeof getFaqs>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFaqs>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetFaqs<TData = Awaited<ReturnType<typeof getFaqs>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFaqs>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetFaqsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves the Intercom HMACs for the user
 */
export const getIntercomHmac = (signal?: AbortSignal) => {
  return customAxios<IntercomHmac>({ url: `/help/intercomHmac`, method: 'GET', signal });
};

export const getGetIntercomHmacQueryKey = () => {
  return [`/help/intercomHmac`] as const;
};

export const getGetIntercomHmacQueryOptions = <
  TData = Awaited<ReturnType<typeof getIntercomHmac>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getIntercomHmac>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIntercomHmacQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIntercomHmac>>> = ({ signal }) => getIntercomHmac(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIntercomHmac>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetIntercomHmacQueryResult = NonNullable<Awaited<ReturnType<typeof getIntercomHmac>>>;
export type GetIntercomHmacQueryError = unknown;

export function useGetIntercomHmac<TData = Awaited<ReturnType<typeof getIntercomHmac>>, TError = unknown>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getIntercomHmac>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getIntercomHmac>>, TError, TData>, 'initialData'>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetIntercomHmac<TData = Awaited<ReturnType<typeof getIntercomHmac>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getIntercomHmac>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getIntercomHmac>>, TError, TData>, 'initialData'>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetIntercomHmac<TData = Awaited<ReturnType<typeof getIntercomHmac>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getIntercomHmac>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetIntercomHmac<TData = Awaited<ReturnType<typeof getIntercomHmac>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getIntercomHmac>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetIntercomHmacQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Creates a task in Amazon Connect for an agent to action
 */
export const createLoginIssueCase = (createLoginIssueCaseBody: CreateLoginIssueCaseBody, signal?: AbortSignal) => {
  return customAxios<void>({
    url: `/help/login-issue`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createLoginIssueCaseBody,
    signal,
  });
};

export const getCreateLoginIssueCaseMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createLoginIssueCase>>,
    TError,
    { data: CreateLoginIssueCaseBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createLoginIssueCase>>,
  TError,
  { data: CreateLoginIssueCaseBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createLoginIssueCase>>,
    { data: CreateLoginIssueCaseBody }
  > = (props) => {
    const { data } = props ?? {};

    return createLoginIssueCase(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateLoginIssueCaseMutationResult = NonNullable<Awaited<ReturnType<typeof createLoginIssueCase>>>;
export type CreateLoginIssueCaseMutationBody = CreateLoginIssueCaseBody;
export type CreateLoginIssueCaseMutationError = unknown;

export const useCreateLoginIssueCase = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createLoginIssueCase>>,
    TError,
    { data: CreateLoginIssueCaseBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createLoginIssueCase>>,
  TError,
  { data: CreateLoginIssueCaseBody },
  TContext
> => {
  const mutationOptions = getCreateLoginIssueCaseMutationOptions(options);

  return useMutation(mutationOptions);
};
