import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { ExternalTransaction, InternalTransaction } from '@cxnpl/api/types';
import type { PaymentRequest } from 'app/api/payments/types';

export interface PaymentsState {
  internalTransfer: InternalTransaction | null;
  externalPayment: PaymentRequest | ExternalTransaction | null;
}

export const initialPaymentsState: PaymentsState = {
  internalTransfer: null,
  externalPayment: null,
};

export const paymentsSlice = createSlice({
  name: 'explore',
  initialState: initialPaymentsState,
  reducers: {
    resetPayments: (state) => {
      state.internalTransfer = null;
      state.externalPayment = null;
    },
    setInternalTransfer: (state, action: PayloadAction<PaymentsState['internalTransfer']>) => {
      state.internalTransfer = action.payload;
    },
    setExternalPayment: (state, action: PayloadAction<PaymentsState['externalPayment']>) => {
      state.externalPayment = action.payload;
    },
  },
});

export const { resetPayments, setInternalTransfer, setExternalPayment } = paymentsSlice.actions;

export default paymentsSlice.reducer;
