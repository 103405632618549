import type { AxiosRequestHeaders } from 'axios';
import type { AppStore } from 'app/store';

/**
 * Function that adds headers to the axios config. Is mutating the headers object so has no return variable
 */
export const setHeaders = ({ headers, store }: { headers: AxiosRequestHeaders; store: AppStore }) => {
  const state = store.getState();
  const authInfo = state.auth;

  if (authInfo.deviceId) {
    headers.deviceId = authInfo.deviceId;
  }
};
