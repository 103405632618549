import type { GetProps } from '@tamagui/web';
import { styled, withStaticProperties } from '@tamagui/web';
import { Stack, XStack, YStack } from 'tamagui';
import { Pencil } from '@tamagui/lucide-icons';
import { Text } from '../Text';
import type { IconButtonProps } from '../IconButton';
import { IconButton } from '../IconButton';

const TableBaseFrame = styled(YStack, {
  name: 'Table',
  tag: 'table',
  width: '100%',
  borderWidth: 1,
  borderRadius: '$surface/radius/surface-radius',
  borderColor: '$border/surface-subdued',
  overflow: 'hidden',
  variants: {
    background: {
      transparent: {
        backgroundColor: '$background/transparent',
      },
      standard: {
        backgroundColor: '$background/surface',
      },
    },
  },
  defaultVariants: {
    background: 'standard',
  },
} as const);

const TableKeyText = styled(Text, {
  name: 'TableKeyText',
  variant: 'bodySmall',
  maxWidth: '100%',
  whiteSpace: 'pre-wrap',
} as const);

const TableValueText = styled(Text, {
  name: 'TableValueText',
  variant: 'bodyMediumEm',
  flex: 1,
  overflow: 'hidden',
  whiteSpace: 'pre-wrap',
  $tablet: {
    textAlign: 'right',
  },
} as const);

const TableHeaderText = styled(Text, {
  name: 'TableValueText',
  variant: 'bodyMediumEm',
  maxWidth: '100%',
  whiteSpace: 'pre-wrap',
} as const);

const TableRow = styled(XStack, {
  name: 'TableRow',
  tag: 'tr',
  width: '100%',
  borderColor: '$border/surface-subdued',
  paddingHorizontal: '$lg',
  paddingVertical: '$sm',
  minHeight: '$size.5xl',
  alignItems: 'center',
} as const);

const TableBody = styled(YStack, {
  name: 'TableBody',
  tag: 'tbody',
} as const);

const TableHead = styled(YStack, {
  name: 'TableHead',
  tag: 'thead',
  backgroundColor: '$background/surface-highlight',
} as const);

const TableEditButton = (props: Omit<Partial<IconButtonProps>, 'variant'>) => {
  return <IconButton icon={<Pencil />} variant="icon-only" size="md" {...props} />;
};

TableEditButton.displayName = 'TableEditButton';

const TableHeader = styled(Stack, {
  name: 'TableHeader',
  tag: 'th',
  flex: 1,
  flexDirection: 'row',
  height: '100%',
  alignItems: 'center',
} as const);

const TableData = styled(Stack, {
  name: 'TableData',
  tag: 'td',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
} as const);

const TableRowHighlighted = styled(TableRow, {
  variants: {
    background: {
      transparent: {
        backgroundColor: '$background/transparent',
      },
      standard: {
        backgroundColor: '$background/surface',
      },
      'primary-hover': {
        backgroundColor: '$background/primary-hover',
      },
      highlight: {
        backgroundColor: '$alert/color/neutral/alert-bg-neutral',
      },
    },
    borderTop: {
      divider: {
        borderTopColor: '$foreground/app-default',
        borderTopWidth: 1,
      },
      default: {
        borderTopColor: undefined,
        borderTopWidth: 0,
      },
    },
  } as const,
  defaultVariants: {
    background: 'standard',
    borderTop: 'default',
  },
} as const);

export const TableBase = withStaticProperties(TableBaseFrame, {
  KeyText: TableKeyText,
  ValueText: TableValueText,
  HeaderText: TableHeaderText,
  Row: TableRow,
  Data: TableData,
  Header: TableHeader,
  Body: TableBody,
  Head: TableHead,
  EditButton: TableEditButton,
  RowHighlighted: TableRowHighlighted,
});

export type TableBaseProps = GetProps<typeof TableBase>;
